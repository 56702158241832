//@ts-nocheck

import { useQuery } from 'react-query'
import { getUserQuestSocial } from '../../api/userQuestSocial'
import QuestQuestsItem from '../QuestQuestsItem/QuestQuestsItem'
import './QuestQuests.scss'
import { useState } from 'react'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import { initDataUserQuestCount } from '../../utils/initData'
import { useTranslation } from 'react-i18next'

function QuestQuests() {

    const { t } = useTranslation()
    const [section, setSection] = useState('new')

    const { data, isLoading, isError, isSuccess, isFetching } = useQuery(
        ['userQuestSocial'],
        () => getUserQuestSocial(),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                initDataUserQuestCount()
            }
        }
    )

    return (

        <>
            <div className='w-100' style={{ background: "rgba(23, 16, 39, 0.8)", marginTop: '5px', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '8px', paddingRight: '8px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
                <div className={'hero-header justify-content-between'}>
                    <div style={{width: '100px'}} className={`hero-header-btn-container text-center ${section === 'new' ? "hero-header-active-btn" : ""}`}
                        onClick={() => setSection('new')}>
                        {t("questQuests.sections.new")}
                    </div>
                    <div style={{width: '100px'}} className={`hero-header-btn-container text-center ${section === 'completed' ? "hero-header-active-btn" : ""}`}
                        onClick={() => setSection('completed')}>
                        {t("questQuests.sections.completed")}
                    </div>
                </div>
            </div>
            <div className='quests-container overflow-auto w-100'>

                {isSuccess &&
                    <>
                        {data.questSocial.map((questSocialEl, index) => {
                            if(section === "new" && !questSocialEl.completed) {
                                return (
                                    <QuestQuestsItem
                                        key={questSocialEl.id}
                                        id={questSocialEl.id}
                                        title={questSocialEl.title}
                                        link={questSocialEl.link}
                                        amount={questSocialEl.amount}
                                        pathImage={questSocialEl.pathImage}
                                        completedProps={questSocialEl.completed}
                                        logo={questSocialEl.logo}
                                        titleEn={questSocialEl.titleEn}
                                        type={questSocialEl.type}
                                        active={questSocialEl.active}
                                        chatId={questSocialEl.chatId}
                                        check={questSocialEl.check}
                                    />
                                )
                            }
                            if(section === "completed" && questSocialEl.completed){
                                return (
                                    <QuestQuestsItem
                                        key={questSocialEl.id}
                                        id={questSocialEl.id}
                                        title={questSocialEl.title}
                                        link={questSocialEl.link}
                                        amount={questSocialEl.amount}
                                        pathImage={questSocialEl.pathImage}
                                        completedProps={questSocialEl.completed}
                                        logo={questSocialEl.logo}
                                        titleEn={questSocialEl.titleEn}
                                        type={questSocialEl.type}
                                        active={questSocialEl.active}
                                        chatId={questSocialEl.chatId}
                                        check={questSocialEl.check}
                                    />
                                )
                            }
                        })
                        }
                    </>
                }

                {isLoading &&
                    <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                }

                {isError &&
                    <DataFetchError text={t("questQuests.dataFetchError")} />
                }

            </div>
        </>
    )
}

export default QuestQuests
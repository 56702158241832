//@ts-nocheck

import "./Heroes.scss"

import HeroContainer from "../../components/HeroContainer/HeroContainer";
import { useTranslation } from 'react-i18next'
import ChangeButton from "../../components/HeroHeader/ChangeButton/ChangeButton";
import LevelButton from "../../components/HeroHeader/LevelButton/LevelButton";
import MinesContainer from "../../components/Mines/MinesContainer";
import {useState} from "react";
import ToastInfo from "../../components/Toasts/ToastInfo/ToastInfo";

function Heroes() {

    const {t} = useTranslation()

    const [curPage, setCurPage] = useState('heroes')

    return (
        <main className='w-100 d-flex flex-column align-items-center main-background main-container'>
            <section className='w-100 h-100 heroes-container d-flex flex-column justify-content-between'>
                <div className={'hero-header justify-content-between'}>
                    <div className={''}>
                        <ChangeButton/>
                    </div>
                    <div className={`hero-header-btn-container text-center ${curPage === 'heroes' ? "hero-header-active-btn" : ""}`}
                         onClick={() => setCurPage('heroes')}>
                        {t("heroHeader.heroes")}
                    </div>
                    <div className={`hero-header-btn-container text-center ${curPage === 'mines' ? "hero-header-active-btn" : ""}`}
                         onClick={() => setCurPage('mines')}>
                        {t("heroHeader.mines")}
                    </div>
                    <div onClick={() => ToastInfo(t("toast.toastInfo"), true)} id='hero-header-workshop' className='hero-header-btn-container text-center'>
                        {t("heroHeader.workshop")}
                    </div>
                    <div className={''}>
                        <LevelButton/>
                    </div>
                </div>

                {curPage === 'heroes' && 
                    <HeroContainer/>
                }
                {curPage === 'mines' && 
                    <MinesContainer/>
                }
            </section>
        </main>
    )
}

export default Heroes
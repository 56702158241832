//@ts-nocheck

import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function getActive() {
    const { data } = await axios.get(
        apiURL + '/api/v1/event-battle-reward',
        axiosConfig
    )
    return data
}

export async function getActiveClans() {
    const { data } = await axios.get(
        apiURL + '/api/v1/event-battle-reward/clans',
        axiosConfig
    )
    return data
}

export async function getPreviousRating() {
    const { data } = await axios.get(
        apiURL + '/api/v1/event-battle-reward/get-previous-rating',
        axiosConfig
    )
    return data
}
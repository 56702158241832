//@ts-nocheck

import './EventContainerClans.scss'
import EventContainerDesc from '../EventContainerDesc/EventContainerDesc'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getActiveClans } from '../../../api/eventBattleReward'
import { MemoCountdown } from '../../MemoCountdown/MemoCountdown'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../../DataFetchError/DataFetchError'
import EventContainerModalClans from '../EventContainerModalClans/EventContainerModalClans'
import EventContainerClansItems from '../EventContainerClansItems/EventContainerClansItems'

export default function EventContainerClans() {

  const { t, i18n } = useTranslation()

  const { data, isLoading, isError, isSuccess, refetch } = useQuery(
    ['eventBattleRewardClans'],
    () => getActiveClans(),
    {
      keepPreviousData: true,
    }
  )

  return (
    <div className='w-100 h-100'>
        {isSuccess &&
            <>
                <div className={'event-container-desc w-100 d-flex flex-column position-relative'}>
                    <div className='d-flex justify-content-between'>
                        <div className='event-container-desc-title d-flex w-100' style={{ zIndex: 2 }}>
                            {i18n.language === 'en' &&
                                (data.eventBattleReward.titleEn)
                            }
                            {i18n.language === 'ru' &&
                                (data.eventBattleReward.titleRu)
                            }
                        </div>
                        <div className='text-white' style={{paddingRight: '8px', paddingTop: '18px', zIndex: 2}}>
                            <MemoCountdown timeAwait={data.timeAwait}/>
                        </div>
                    </div>
                    <div className='event-container-desc-text d-flex w-100 z-n2' style={{ zIndex: 2 }}>
                        <ul>
                            <li>{t("eventRaiting.text_1")}</li>
                            <li>{t("eventRaiting.text_2")}</li>
                            <li>{t("eventRaiting.text_3")}</li>
                            <li>{t("eventRaiting.text_4")}</li>
                        </ul>
                    </div>
                    <div className='event-container-desc-button d-flex justify-content-center position-relative' data-bs-toggle="modal" data-bs-target="#eventModalClans" style={{ zIndex: 2 }}>
                        <svg width='94' height='28' viewBox="0 0 130 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M129 19.8599L110.945 38.7166H29.0301L8.30322 19.8599L29.0301 1H110.945L129 19.8599Z" stroke="#B0B0B0" strokeMiterlimit="10" />
                            <path d="M122.697 20.1433L104.641 39H22.7269L2 20.1433L22.7269 1.28339H104.641L122.697 20.1433Z" stroke="#B0B0B0" strokeWidth="2" strokeMiterlimit="10" />
                        </svg>

                        <div className={'event-container-desc-button_text position-absolute top-50 start-50 translate-middle'}>{t("eventRaiting.rewards")}</div>
                    </div>
                    <div className='event-container-desc-image position-absolute top-50 end-0 translate-middle-y'>
                        <img src={`/img/event/background/${data.eventBattleReward.topHero.pathImage}.png`} width={'auto'} height={'100%'} style={{ zIndex: 1, opacity: 0.3 }} />
                    </div>

                    <EventContainerModalClans />

                </div>

                <EventContainerClansItems usersOldCoinEvent={data.clansOldCoinEvent} />
            </>
        }

        {isLoading &&
            <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
        }

        {isError &&
            <DataFetchError text={t("mailContainer.dataFetchError")} />
        }

    </div>

)
}
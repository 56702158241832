import './Refferal.scss'

import RefferalRewardContainer from '../../components/RefferalRewardContainer/RefferalRewardContainer';
import RefferalRewardUser from '../../components/RefferalRewardUser/RefferalRewardUser';
import RefferalRewardPremiumUser from '../../components/RefferalRewardPremiumUser/RefferalRewardPremiumUser';
import RefferalInfo from '../../components/RefferalInfo/RefferalInfo';
import RefferalRewardButtonContainer from '../../components/RefferalRewardButtonContainer/RefferalRewardButtonContainer';
import RefferalFriendsContainer from '../../components/RefferalFriendsContainer/RefferalFriendsContainer';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

function Refferal() {

  const { t } = useTranslation()

  const [curPage, setCurPage] = useState('rewards')

  return (
    <main className='w-100 d-flex flex-column align-items-center main-background main-container'>
      <div className='w-100 d-flex' style={{ marginTop: '15px' }}>
        <RefferalRewardUser />
        <RefferalRewardPremiumUser />
      </div>
      <RefferalInfo />
      <div className='w-100' style={{background: "rgba(23, 16, 39, 0.8)", marginTop: '5px', paddingTop: '5px', paddingBottom:'5px', paddingLeft:'8px', paddingRight: '8px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}>
        <div className={'hero-header justify-content-between'}>
          <div style={{width: '100px'}} className={`hero-header-btn-container text-center ${curPage === 'rewards' ? "hero-header-active-btn" : ""}`}
            onClick={() => setCurPage('rewards')}>
            {t("refferal.sections.rewards")}
          </div>
          <div style={{width: '100px'}} className={`hero-header-btn-container text-center ${curPage === 'friends' ? "hero-header-active-btn" : ""}`}
            onClick={() => setCurPage('friends')}>
            {t("refferal.sections.friends")}
          </div>
        </div>
      </div>
      <div className='h-100 w-100 d-flex' style={{overflow: 'hidden'}}>
        {curPage === "rewards" &&
          <RefferalRewardContainer />
        }
        {curPage === "friends" &&
          <RefferalFriendsContainer />
        }
      </div>
      <RefferalRewardButtonContainer />
      {/* <RefferalFriendsContainer /> */}
    </main>
  )
}

export default Refferal
//@ts-nocheck
import React, { useEffect, useState } from 'react'
import './IsFirstTimeSlider.scss'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFirstTime } from '../../redux/userSlice/userSlice';
import { useTranslation } from 'react-i18next';

export default function IsFirstTimeSlider() {

    const { t } = useTranslation()

    const [sliderIndex, setSliderIndex] = useState(0)
    const dispatch = useDispatch()
    const isFirstTime = useSelector((state) => state.user.isFirstTime)

    useEffect(() => {
        if (!isFirstTime) return
        const myOffcanvas = document.getElementById('isFirstTimeSliderOffcanvas')
        const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
        bsOffcanvas.show()
    }, [isFirstTime])

    return (
        <div style={{ zIndex: 9999 }} className='offcanvas offcanvas-bottom bg-transparent is-first-time-slider w-100 h-100 position-fixed top-0 start-0' tabIndex="-1" id={"isFirstTimeSliderOffcanvas"} aria-labelledby="offcanvasBottomLabel">
            <div className='w-100 h-100 d-flex align-items-end'>
                <div className='is-first-time-slider-container w-100  d-flex flex-column' style={{ paddingTop: '12px' }}>
                    <div className='w-100 h-100'>
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            totalSlides={6}
                            dragEnabled={false}
                            className='w-100 h-100 d-flex flex-column'
                        >
                            <Slider className='w-100 h-100 d-flex flex-column' style={{ height: '310px !important' }}>
                                <Slide className='h-100 text-white is-first-time-slider-item d-flex justify-content-center' index={0}>
                                    <div className='w-100 text-white position-relative d-flex justify-content-end width-368'>
                                        <img src='/img/isFirstTimeSlider/0.png' style={{ zIndex: 1 }} className='position-absolute start-0 top-0' height={310} width={255} />
                                        <div style={{ width: '201px', zIndex: 2 }}>
                                            <p className='is-first-time-slider-item-text' style={{ marginBottom: '10px', lineHeight: '14px' }}>
                                                {t("isFirstTimeSlider.slider0.text1")}
                                            </p>
                                            <p className='is-first-time-slider-item-text'>
                                                {t("isFirstTimeSlider.slider0.text2")}
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide className='h-100 text-white is-first-time-slider-item d-flex justify-content-center' index={1}>
                                    <div className='w-100 text-white position-relative d-flex align-items-end width-368'>
                                        <img src='/img/isFirstTimeSlider/1.png' style={{ zIndex: 1 }} className='position-absolute start-0 top-0' height={308} width={368} />
                                        <div style={{ zIndex: 2}} className='d-flex flex-column justify-content-end'>
                                            <p className='is-first-time-slider-item-text' style={{ zIndex: 2, lineHeight: '14px' }}>
                                                {t("isFirstTimeSlider.slider1.text1")}
                                            </p>
                                            <ul>
                                                <li style={{lineHeight: '14px'}} className='is-first-time-slider-item-text'>{t("isFirstTimeSlider.slider1.text2")}</li>
                                                <li style={{lineHeight: '14px'}} className='is-first-time-slider-item-text'>{t("isFirstTimeSlider.slider1.text3")}</li>
                                                <li style={{lineHeight: '14px'}} className='is-first-time-slider-item-text'>{t("isFirstTimeSlider.slider1.text4")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide className='h-100 text-white is-first-time-slider-item' index={2}>
                                    <div className='w-100 text-white position-relative d-flex flex-column justify-content-between width-368'>
                                        <p className='is-first-time-slider-item-text w-100'>
                                            {t("isFirstTimeSlider.slider2.text1")}
                                        </p>
                                        <img src='/img/isFirstTimeSlider/2.png' style={{ zIndex: 1 }} height={245} width={352} />
                                    </div>
                                </Slide>
                                <Slide className='h-100 text-white is-first-time-slider-item' index={3}>
                                    <div className='w-100 text-white position-relative d-flex flex-column justify-content-between width-368'>
                                        <p className='is-first-time-slider-item-text w-100'>
                                            {t("isFirstTimeSlider.slider3.text1")}
                                        </p>
                                        <img src='/img/isFirstTimeSlider/3.png' style={{ zIndex: 1 }} height={257} width={352} />
                                    </div>
                                </Slide>
                                <Slide className='h-100 text-white is-first-time-slider-item' index={4}>
                                    <div className='w-100 text-white position-relative d-flex flex-column width-368'>
                                        <img src='/img/isFirstTimeSlider/4.png' className='position-absolute' style={{ zIndex: 1 }} height={296.39} width={352} />
                                        <div className='is-first-time-slider-item-text d-flex justify-content-end'>
                                            <p className='is-first-time-slider-item-text' style={{ maxWidth: '192px' }}>
                                                {t("isFirstTimeSlider.slider4.text1")}
                                            </p>
                                        </div>
                                        <div className='is-first-time-slider-item-text d-flex align-items-end'>
                                            <p className='is-first-time-slider-item-text' style={{ maxWidth: '184px', paddingBottom: '32px' }} >
                                                {t("isFirstTimeSlider.slider4.text2")}
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide className='h-100 text-white is-first-time-slider-item' index={5}>
                                    <div className='w-100 text-white position-relative d-flex flex-column width-368'>
                                        <img src='/img/isFirstTimeSlider/0.png' style={{ zIndex: 1, opacity: '30%' }} className='position-absolute end-0 top-0' height={310} width={255} />
                                        <div style={{ maxWidth: '334px', zIndex: 2 }}>
                                            <p className='is-first-time-slider-item-text' style={{ marginBottom: '10px' }}>
                                                {t("isFirstTimeSlider.slider5.text1")}
                                            </p>
                                            <p className='is-first-time-slider-item-text'>
                                                {t("isFirstTimeSlider.slider5.text2")}
                                            </p>
                                        </div>
                                        <div className='d-flex justify-content-center' style={{ paddingBottom: '28px', zIndex: 2 }}>
                                            <section className='position-relative' style={{ width: '119px', height: '120px !important' }}>
                                                <img src='/img/isFirstTimeSlider/bonus.png' width={119} height={120} />
                                                <p className='is-first-time-slider-item-bonus position-absolute end-0 d-flex justify-content-center align-items-center'>
                                                    500
                                                </p>
                                            </section>
                                        </div>
                                    </div>
                                </Slide>
                            </Slider>

                            <div className='is-first-time-slider-dots d-flex justify-content-center' style={{ paddingTop: '8px', gap: '4px' }}>
                                <Dot slide={0} onClick={() => setSliderIndex(0)} />
                                <Dot slide={1} onClick={() => setSliderIndex(1)} />
                                <Dot slide={2} onClick={() => setSliderIndex(2)} />
                                <Dot slide={3} onClick={() => setSliderIndex(3)} />
                                <Dot slide={4} onClick={() => setSliderIndex(4)} />
                                <Dot slide={5} onClick={() => setSliderIndex(5)} />
                            </div>

                            <div className='w-100 d-flex justify-content-end' style={{ paddingRight: '22px', paddingBottom: '22px', paddingTop: '13px' }}>
                                {sliderIndex !== 5 &&
                                    <ButtonNext onClick={() => setSliderIndex(sliderIndex + 1)} className='is-first-time-slider-btn position-relative p-0 m-0'>
                                        <>
                                            <svg width="180" height="52" viewBox="0 0 180 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M170.408 26.1887L145.215 51.0002H30.9201L2 26.1887L30.9201 1.37305H145.215L170.408 26.1887Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                                                <path d="M179.203 25.8157L154.01 50.6272H39.7149L10.7948 25.8157L39.7149 1H154.01L179.203 25.8157Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                            </svg>
                                            <div className='position-absolute top-50 start-50 translate-middle is-first-time-slider-btn-text'>
                                                {t("isFirstTimeSlider.next")}
                                            </div>
                                        </>
                                    </ButtonNext>
                                }
                                {sliderIndex === 5 &&
                                    <div data-bs-dismiss="offcanvas" aria-label="Закрыть" className='is-first-time-slider-btn position-relative p-0 m-0'>
                                        <svg width="180" height="52" viewBox="0 0 180 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M170.408 26.1887L145.215 51.0002H30.9201L2 26.1887L30.9201 1.37305H145.215L170.408 26.1887Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                                            <path d="M179.203 25.8157L154.01 50.6272H39.7149L10.7948 25.8157L39.7149 1H154.01L179.203 25.8157Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                        </svg>
                                        <div className='position-absolute top-50 start-50 translate-middle is-first-time-slider-btn-text'>
                                            {t("isFirstTimeSlider.close")}
                                        </div>
                                    </div>
                                }
                            </div>

                        </CarouselProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}
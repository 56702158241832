//@ts-nocheck

import RefferalFriendsItem from '../RefferalFriendsItem/RefferalFriendsItem'
import './RefferalFriendsContainer.scss'
import { getUserRefferal } from '../../api/refferalReward'
import { useQuery } from 'react-query';
import Countdown from 'react-countdown';
import { useState } from 'react';
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../DataFetchError/DataFetchError';
import { useTranslation } from 'react-i18next';


function RefferalFriendsContainer() {

    const { t } = useTranslation()

    const [isTimerComplete, setIsTimerComplete] = useState(true)

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['userRefferal'],
        () => getUserRefferal(),
        {
            keepPreviousData: true,
        }
    )

    const timerComplete = () => {
        setIsTimerComplete(true)
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return;
        } else {
            return <span className='refferal-friends-container-reload-text'>{(seconds < 10 ? '0' : '') + seconds}</span>;
        }
    }

    const onClickGetUserRefferal = () => {
        if (isTimerComplete === false)
            return

        setIsTimerComplete(false)
        refetch()
    }

    return (
        <div className='w-100 h-100 d-flex flex-column'>
            <div className='refferal-friends-container-header d-flex justify-content-between align-items-center'>
                <div className='refferal-friends-container-title'>{t("refferalFriendsContainer.title") + (isSuccess ? data.data.refferals.length : 0)}</div>
            </div>
            <div className='refferal-friends-container w-100 overflow-hidden h-100'>
                <div className='refferal-friends-container-friends h-100'>
                    {isSuccess && data.status == 200 &&
                        <>
                            {data.data.refferals.map((refferal, index) => {
                                return (
                                    <RefferalFriendsItem
                                        key={index}
                                        firstName={refferal.firstName}
                                        lastName={refferal.lastName}
                                        username={refferal.username}
                                        isPremium={refferal.isPremium}
                                    />
                                )
                            })
                            }
                        </>
                    }

                    {isLoading &&
                        <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                    }

                    {isError &&
                        <DataFetchError text={t("refferalFriendsContainer.dataFetchError")} />
                    }
                </div>

            </div>
            </div>
    )
}

export default RefferalFriendsContainer
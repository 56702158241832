import React from 'react'
import './SkipTime.scss'

export default function SkipTime() {
    return (
        <div className={'h-100 d-flex align-items-center'} style={{ marginTop: '8px' }}>
            <div className='combo-stars d-flex'>
                <div className='combo-stars-item d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#skipTime">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.9366 15.012C21.9785 14.6806 22 14.3428 22 14C22 9.58172 18.4182 6 14 6C9.58172 6 6 9.58172 6 14C6 18.4182 9.58172 22 14 22C14.3483 22 14.6914 21.9778 15.028 21.9346M14 9.2V14L16.9907 15.4954M19.6 22V17.2M17.2 19.6H22" stroke="#171027" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M27 14C27 20.1282 27 23.1924 25.0961 25.0961C23.1924 27 20.1282 27 14 27C7.87173 27 4.80761 27 2.90381 25.0961C1 23.1924 1 20.1282 1 14C1 7.87173 1 4.80761 2.90381 2.90381C4.80761 1 7.87173 1 14 1C20.1282 1 23.1924 1 25.0961 2.90381C26.3621 4.16967 26.7863 5.94851 26.9284 8.8" stroke="#171027" stroke-linecap="round" />
                    </svg>
                </div>
            </div>
        </div>
    )
}
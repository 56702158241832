import { ritualStoneHeroesSlice } from './ritualStoneHeroesSlice/ritualStoneHeroesSlice';
import { userHeroBonusSlice } from './userHeroBonusSlice/userHeroBonusSlice';
import { userSlice } from './userSlice/userSlice';
import { userBanSlice } from './userBanSlice/userBanSlice';
import { userLootEnergyBottleSlice } from './userLootEnergyBottleSlice/userLootEnergyBottleSlice';
import { userNotificationsSlice } from './userNotificationsSlice/userNotificationsSlice';
import { userOfflineRewardSlice } from './userOfflineRewardSlice/userOfflineRewardSlice';
import { configureStore } from '@reduxjs/toolkit'

import userLootGoldReducer from './userLootGoldSlice/userLootGoldSlice'
import userLootCrystalReducer from './userLootCrystalSlice/userLootCrystalSlice'
import userMonsterHealthReducer from './userMonsterHealth/userMonsterHealth'
import userMonsterAnimationReducer from './userMonsterAnimation/userMonsterAnimation'
import userHeroesDamageReducer from './userHeroesDamageSlice/userHeroesDamageSlice'
import userHeroViewReducer from './userHeroViewSlice/userHeroViewSlice'
import userLevelReducer from './userLevelSlice/userLevelSlice'
import userHeroBtnLevelCountReducer from './userHeroBtnLevelCountSlice/userHeroBtnLevelCountSlice'
import userMonsterChangeReducer from './userMonsterChange/userMonsterChange'
import userMonsterIsSpawnReducer from './userMonsterIsSpawn/userMonsterIsSpawn'
import userMonsterCounterReducer from './userMonsterCounter/userMonsterCounter'
import userEnergyCountReducer from './userEnergyCountSlice/userEnergyCountSlice'
import userQuestCountReducer from './userQuestCountSlice/userQuestCountSlice'
import userOfflineRewardReducer from './userOfflineRewardSlice/userOfflineRewardSlice'
import userApiErrorReducer from './Error/userApiError/userApiErrorSlice'
import userApiErrorHashReducer from './Error/userApiErrorHash/userApiErrorHashSlice'
import startLoadingReducer from './startLoadingSlice/startLoadingSlice'
import userOldCoinReducer from './userOldCoinSlice/userOldCoinSlice'
import userBattlesStartReducer from './userBattlesStartSlice/userBattlesStartSlice'
import userLootOldCoinReducer from './userLootOldCoinSlice/userLootOldCoinSlice'
import userNotificationsReducer from './userNotificationsSlice/userNotificationsSlice'
import userLootEnergyBottleReducer from './userLootEnergyBottleSlice/userLootEnergyBottleSlice'
import userBanReducer from './userBanSlice/userBanSlice'
import userReducer from './userSlice/userSlice'
import userHeroBonusReducer from './userHeroBonusSlice/userHeroBonusSlice'
import ritualStoneHeroesReducer from './ritualStoneHeroesSlice/ritualStoneHeroesSlice'
import eventPrintingReducer from './eventPrintingSlice/eventPrintingSlice'

export default configureStore({
  reducer: {
    userLootGold:     userLootGoldReducer,
    userLootCrystal:  userLootCrystalReducer,
    userHeroesDamage: userHeroesDamageReducer,
    userHeroView:     userHeroViewReducer,
    userLevel:        userLevelReducer,
    userHeroBtnLevelCount: userHeroBtnLevelCountReducer,
    userMonsterHealth: userMonsterHealthReducer,
    userMonsterAnimation: userMonsterAnimationReducer,
    userMonsterChange: userMonsterChangeReducer,
    userMonsterIsSpawn: userMonsterIsSpawnReducer,
    userMonsterCounter: userMonsterCounterReducer,
    userEnergyCount: userEnergyCountReducer,
    userQuestCount: userQuestCountReducer, 
    userOfflineReward: userOfflineRewardReducer,
    startLoading: startLoadingReducer,
    userOldCoin: userOldCoinReducer,
    userBattlesStart: userBattlesStartReducer,
    userLootOldCoin: userLootOldCoinReducer,
    userNotifications: userNotificationsReducer,
    userLootEnergyBottle: userLootEnergyBottleReducer,
    userBan: userBanReducer,
    user: userReducer,
    userHeroBonus: userHeroBonusReducer,
    ritualStoneHeroes: ritualStoneHeroesReducer,
    eventPrinting: eventPrintingReducer,
    
    //errors
    userApiError: userApiErrorReducer, 
    userApiErrorHash: userApiErrorHashReducer,
  },
})
//@ts-nocheck

import './EventContainer.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import EventContainerUsers from './EventContainerUsers/EventContainerUsers'
import EventContainerClans from './EventContainerClans/EventContainerClans'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import { useQuery } from 'react-query'
import { get } from '../../api/rating'
import ToastInfo from '../Toasts/ToastInfo/ToastInfo'

export default function EventContainer() {

    const { t } = useTranslation()

    const [activeNavbarItem, setActiveNavbarItem] = useState('')

    useEffect(() => {
        setActiveNavbarItem('users')
    }, [])

    return (
        <div className='event-container w-100 h-100 d-flex flex-column'>

            <div className='event-container-navbar d-flex justify-content-between'>
                <div onClick={() => setActiveNavbarItem('users')} className={`event-container-navbar-item ${activeNavbarItem === 'users' ? 'event-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("eventRaiting.users")}
                </div>

                <div onClick={() => setActiveNavbarItem('clans')} className={`event-container-navbar-item ${activeNavbarItem === 'clans' ? 'event-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("eventRaiting.clans")}
                </div>
            </div>

            <div className='event-container-items-container overflow-auto h-100'>

                {activeNavbarItem === 'users' &&
                    <EventContainerUsers />
                }
                {activeNavbarItem === 'clans' &&
                    <EventContainerClans />
                }

            </div>
        </div>
    )

}
import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function getByUserId() {
    const { data } = await axios.get(
        apiURL + '/api/v1/user-message', 
        axiosConfig
    )    
    return data
}

export async function claim(id: number) {
    const { data } = await axios.post(
        apiURL + '/api/v1/user-message/claim', 
        {
            id: id,
        },
        axiosConfig
    )    
    return data
}

export async function deleteById(id: number) {
    const { data } = await axios.post(
        apiURL + '/api/v1/user-message/delete-by-id', 
        {
            id: id,
        },
        axiosConfig
    )    
    return data
}

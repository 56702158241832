//@ts-nocheck

import { useQueryClient } from 'react-query';
import './HeroNewModal.scss'
import { initDataUserHeroesDamage } from '../../utils/initData';
import { useTranslation } from 'react-i18next';

function HeroNewModal({ hero, type = "" }) {

    const { t, i18n } = useTranslation()

    const queryClient = useQueryClient();

    const heroNewModalOnClick = () => {
        if (type === "shop") {
            queryClient.invalidateQueries(['shopHero'])
        }

        initDataUserHeroesDamage()
    }

    return (
        <div class="modal fade" id={'heroNewModal' + hero.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" style={{ backdropFilter: 'blur(5px)' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-transparent p-0 m-0">
                    <div className='hero-new-modal w-100 d-flex'>
                        <div className='w-100 d-flex flex-column justify-content-around' style={{ paddingRight: '8px' }}>
                            <div className='hero-new-modal-title'>
                                {t("heroNewModal.title")}
                            </div>
                            <div className='hero-new-modal-name'>
                                {i18n.language === 'en' &&
                                    (hero.nameEn)
                                }
                                {i18n.language === 'ru' &&
                                    (hero.nameRu)
                                }
                            </div>
                            <div className='w-100 d-flex justify-content-end'>
                                <div className='position-relative' data-bs-dismiss="modal" style={{ cursor: 'pointer' }}>
                                    <div onClick={heroNewModalOnClick} className='hero-new-modal-btn position-absolute top-50 start-50 translate-middle'>{t("heroNewModal.button")}</div>
                                    <svg width="123" height="38" viewBox="0 0 123 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M122.316 18.8673L105.211 36.7316H27.6076L7.97168 18.8673L27.6076 1H105.211L122.316 18.8673Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                        <path d="M116.344 19.1357L99.2392 37H21.636L2 19.1357L21.636 1.26843H99.2392L116.344 19.1357Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <img src={`/img/heroes/${hero.pathImage}.jpg`} width={130} height={130} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroNewModal
//@ts-nocheck
import './EventContainerModalClans.scss'
import { useTranslation } from "react-i18next"
import { getActive } from '../../../api/eventBattleReward'
import { useQuery } from 'react-query'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../../DataFetchError/DataFetchError'

export default function EventContainerModalClans() {

    const { t, i18n } = useTranslation();

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['eventBattleReward'],
        () => getActive(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className="modal fade overflow-hidden" id="eventModalClans" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
            {isSuccess &&
                <>
                    <div className={'modal-dialog modal-dialog-centered h-100'}>
                        <div className="modal-content event-container-modal w-100 overflow-auto">
                            <div className='text-center text-white'>
                                {t("eventRaiting.rewards")}
                            </div>
                            <div className='event-container-modal-desc d-flex flex-column'>
                                <div>{t("eventRaiting.desc_1")}</div>
                                <div>{t("eventRaiting.desc_3")}</div>
                            </div>
                            <div className='event-container-modal-reward d-flex flex-column'>
                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.bottle")} ( {t("eventContainerModalClans.bottlesLeader")} )</div>
                                    <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center'>
                                        <div className='event-container-modal-reward-image d-flex justify-content-center'>
                                            <img src={`/img/loot/energy-bottles.png`} width={'auto'} height={'60px'} />
                                        </div>
                                        <div className='event-container-modal-reward-text d-flex flex-column w-75'>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>940 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>2 500 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>1 900 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>5 000 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>3 500 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>9 000 шт.</div>
                                            </div>
                                    </div>
                                </div>

                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.fragments")}</div>
                                <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center'>
                                        <div className='event-container-modal-reward-image d-flex justify-content-center'>
                                            <img src={`/img/heroes/fragments/emberid.png`} width={'auto'} height={'60px'} />
                                        </div>
                                        <div className='event-container-modal-reward-text d-flex flex-column w-75'>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>15 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>10 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>50 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>50 шт.</div>
                                            </div>
                                        </div>
                                </div>

                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.fragments")}</div>
                                <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center'>
                                        <div className='event-container-modal-reward-image d-flex justify-content-center'>
                                            <img src={`/img/heroes/fragments/izolar.png`} width={'auto'} height={'60px'} />
                                        </div>
                                        <div className='event-container-modal-reward-text d-flex flex-column w-75'>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>100 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>50 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>250 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>100 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>450 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>150 шт.</div>
                                            </div>
                                        </div>
                                </div>

                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.fragments")}</div>
                                <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center'>
                                        <div className='event-container-modal-reward-image d-flex justify-content-center'>
                                            <img src={`/img/heroes/fragments/efirion.png`} width={'auto'} height={'60px'} />
                                        </div>
                                        <div className='event-container-modal-reward-text d-flex flex-column w-75'>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>250 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>10 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>450 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>30 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>700 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>30 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>1 100 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>50 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>2 500 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>150 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>3 200 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>300 шт.</div>
                                            </div>
                                        </div>
                                </div>

                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.fragments")}</div>
                                <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center'>
                                        <div className='event-container-modal-reward-image d-flex justify-content-center'>
                                            <img src={`/img/heroes/fragments/zaraktus-warrior.png`} width={'auto'} height={'60px'} />
                                        </div>
                                        <div className='event-container-modal-reward-text d-flex flex-column w-75'>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>1 100 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>20 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>1 400 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>50 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>2 500 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>100 шт.</div>
                                            </div>
                                            <div className='event-container-modal-reward-text d-flex justify-content-between w-100'>
                                                <div className='d-flex'>3 200 000 {t("eventRaiting.oldcoin")}</div>
                                                <div className='d-flex'>150 шт.</div>
                                            </div>
                                        </div>
                                </div>

                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.rewards")}</div>
                                <div className='event-container-modal-reward-item d-flex flex-column'>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>15 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>100 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>50 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>500 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>100 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>1 200 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>250 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>1 800 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>450 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>2 300 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>700 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>3 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>1 100 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>5 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>1 400 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>7 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>2 500 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>10 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>3 200 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>15 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                </div>
                                <div className='event-container-modal-reward-name d-flex w-100'></div>
                                <div className='event-container-modal-reward-item d-flex flex-column'>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>15 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>10 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>50 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>30 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>100 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>50 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>250 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>70 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>450 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>100 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>700 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>100 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>1 100 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>150 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>1 400 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>150 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>2 500 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>150 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>3 200 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>250 {t("eventRaiting.bottle")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("eventRaiting.dataFetchError")} />
            }
        </div>
    )

    // return (
    //     <div className="modal fade overflow-hidden" id="eventModalClans" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
    //         {isSuccess &&
    //             <>
    //                 <div className={'modal-dialog modal-dialog-centered h-100'}>
    //                     <div className="modal-content event-container-modal w-100 overflow-auto">
    //                         <div className='text-center text-white'>
    //                             {t("eventRaiting.rewards")}
    //                         </div>
    //                         <div className='event-container-modal-desc d-flex flex-column'>
    //                             <div>{t("eventRaiting.desc_1")}</div>
    //                             <div>{t("eventRaiting.desc_2")}</div>
    //                             <div>{t("eventRaiting.desc_3")}</div>
    //                         </div>
    //                         <div className='event-container-modal-reward d-flex flex-column'>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>15 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>10 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>100 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/emberid.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>10 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>50 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>30 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>500 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/emberid.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>50 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>100 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>50 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>1 200 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/izolar.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>50 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>250 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>70 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>1 800 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/izolar.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>100 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/efirion.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>10 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>450 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>100 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>2 300 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/izolar.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>150 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/efirion.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>30 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>700 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>100 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>3 000 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/efirion.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>30 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>940 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>Лидер клана 2 500 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>1 100 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>150 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>5 000 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/efirion.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>50 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/zaraktus-warrior.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>20 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>1 400 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>150 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>7 000 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/zaraktus-warrior.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>50 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>1 900 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>Лидер клана 5 000 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>2 500 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>150 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>10 000 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/efirion.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>150 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/zaraktus-warrior.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>100 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>3 200 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>250 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/daily-reward-crystal-icon.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>15 000 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/efirion.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>300 шт.</div>
    //                                 </div>
    //                                 <div className='d-flex justify-content-between align-items-center event-container-modal-reward-grey' style={{ paddingTop: '3px', paddingBottom: '3px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/heroes/fragments/zaraktus-warrior.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>150 шт.</div>
    //                                 </div>
    //                             </div>

    //                             <div className='event-container-modal-reward-name d-flex w-100'>3 500 000 {t("eventRaiting.oldcoin")}</div>
    //                             <div className='event-container-modal-reward-item' style={{ padding: '4px' }}>
    //                                 <div className='d-flex justify-content-between align-items-center' style={{ padding: '3px 4px' }}>
    //                                     <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
    //                                         <img src={'/img/loot/energy-bottles-no-back.png'} width={'auto'} height={'30px'} />
    //                                     </div>
    //                                     <div>Лидер клана 9 000 шт.</div>
    //                                 </div>
    //                             </div>

    //                         </div>
    //                     </div>
    //                 </div>
    //             </>
    //         }

    //         {isLoading &&
    //             <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
    //         }

    //         {isError &&
    //             <DataFetchError text={t("eventRaiting.dataFetchError")} />
    //         }
    //     </div>
    // )
}
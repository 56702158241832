//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isFirstTime: false,
    },
    reducers: {
        setIsFirstTime: (state, action) => {
            state.isFirstTime = action.payload
        },
    },
})

export const { setIsFirstTime } = userSlice.actions

export default userSlice.reducer
//@ts-nocheck

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { buy } from '../../../../api/comboStars'
import { initInvoice } from '@telegram-apps/sdk'
import { MemoCountdown } from '../../../MemoCountdown/MemoCountdown'
import { numberFormat } from '../../../../utils/NumberFormat'
import DataFetchLoader from '../../../DataFetchLoader/DataFetchLoader'

export default function ComboStarsModalContent({data}) {

    const { t, i18n } = useTranslation()
    const invoice = initInvoice();

    const [buyIsLoading, setBuyIsLoading] = useState(false)
    const queryClient = useQueryClient()
    const comboStarsModalBuyMutation = useMutation(
        (id: number) => buy(id),
        {
            onSuccess: (data) => {
                if (data.data.invoice.ok === true) {
                    invoice.open(data.data.invoice.result, 'url').then((status) => {
                        if (status === 'paid') {
                            queryClient.invalidateQueries(['userLoot']).then(() => {
                                queryClient.invalidateQueries(['userHeroes']).then(() => {
                                    const myModalId = document.getElementById("comboStars");
                                    var myModal = bootstrap.Modal.getInstance(myModalId, {
                                    });
                                    myModal.hide();
                                    queryClient.invalidateQueries(['comboStars']).then(() => {
                                        setBuyIsLoading(false)
                                    })
                                })
                            })
                        } else {
                            setBuyIsLoading(false);
                        }
                    })
                }

            },
        }
    )
    const comboStarsModalBuy = () => {
        if (buyIsLoading) return

        setBuyIsLoading(true)

        comboStarsModalBuyMutation.mutate(data.comboStars.id)
    }

    return (
        <div className='w-100 h-100 d-flex flex-column justify-content-between'>
            <div className='position-relative' style={{ width: '290px', height: '290px' }}>
                <img style={{ width: '290px', height: '290px', zIndex: 1, borderRadius: '4px' }} src={`/img/combo/heroes/${data.comboStars.comboItemStars.find(item => item.type === "HERO").hero.pathImage}.jpg`} className='position-absolute' />
                <div className='combo-stars-modal-title-container w-100 h-100 position-absolute d-flex flex-column justify-content-between' style={{ zIndex: 2, }}>
                    <div className='combo-stars-modal-title w-100 d-flex align-items-center'>
                        {i18n.language === 'en' &&
                            (data.comboStars.titleEn)
                        }
                        {i18n.language === 'ru' &&
                            (data.comboStars.titleRu)
                        }
                    </div>
                </div>
            </div>
            <div className='w-100 d-flex align-items-center justify-content-between' style={{ paddingBottom: '8px', paddingLeft: '8px', paddingRight: '8px' }}>
                <div className='d-flex'>
                    <div className='combo-stars-modal-item d-flex justify-content-center align-items-center position-relative' style={{ marginRight: '9px' }}>
                        <img src='/img/shop/crystal/bag-large.png' height={48} width={'auto'} />
                        <div className='combo-stars-modal-item-text position-absolute d-flex justify-content-center align-items-center'>
                            {numberFormat(data.comboStars.comboItemStars.find(item => item.type === "CRYSTAL").count)}
                        </div>
                    </div>

                    <div className='combo-stars-modal-item d-flex justify-content-center align-items-center position-relative' style={{ marginRight: '9px' }}>
                        <img src='/img/loot/energy-bottles-no-back.png' height={48} width={'auto'} />
                        <div className='combo-stars-modal-item-text position-absolute d-flex justify-content-center align-items-center'>
                            {numberFormat(data.comboStars.comboItemStars.find(item => item.type === "ENERGYBOTTLE").count)}
                        </div>
                    </div>

                    <div className='combo-stars-modal-item d-flex justify-content-center align-items-center position-relative'>
                        <img src={`/img/heroes/${data.comboStars.comboItemStars.find(item => item.type === "HERO").hero.pathImage}.jpg`} height={48} width={48} />
                        <div className='combo-stars-modal-item-text position-absolute d-flex justify-content-center align-items-center'>
                            {t("comboStarsModal.hero")}
                        </div>
                    </div>
                </div>

                <div onClick={comboStarsModalBuy} className="hero-details-modal-info-button-container w-100 d-flex justify-content-center position-relative">
                    <div className="hero-details-modal-btn position-absolute top-50 start-50 translate-middle">
                        {!buyIsLoading && (
                            <div className="d-flex align-items-center">
                                <div className={`hero-details-modal-buy-continue`}>
                                    <div className='combo-stars-modal-buy text-center'>
                                        {t("comboStarsModal.buy")}
                                    </div>
                                    <div className='combo-stars-modal-price d-flex align-items-center justify-content-center'>
                                        <img src='/img/loot/star-icon.png' width={12.58} style={{ marginRight: '3px' }} />
                                        <span>{data.comboStars.price}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {buyIsLoading && (
                            <div className="position-absolute top-50 start-50 translate-middle">
                                <DataFetchLoader width={17} height={17} color={"#B28E4C"} />
                            </div>
                        )}
                    </div>
                    <svg width="82" height="35" viewBox="0 0 82 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M81.211 17.3783L69.8076 33.7539H18.0721L4.98145 17.3783L18.0721 1H69.8076L81.211 17.3783Z" stroke="#B28E4C" stroke-miterlimit="10" />
                        <path d="M77.2296 17.6244L65.8261 34H14.0906L1 17.6244L14.0906 1.24609H65.8261L77.2296 17.6244Z" stroke="#B28E4C" stroke-miterlimit="10" />
                    </svg>
                </div>

            </div>
        </div>
    )
}
import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function skip() {
    const { data } = await axios.post(
        apiURL + '/api/v1/skip',
        {},
        axiosConfig
    )
    return data
}
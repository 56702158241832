//@ts-nocheck

import './MailContainerModal.scss';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { numberFormat } from '../../../utils/NumberFormat';
import ToastError from '../../Toasts/ToastError/ToastError';
import { useMutation, useQueryClient } from 'react-query';
import { claim } from '../../../api/userMessage';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import { initDataUserNotification } from '../../../utils/initData';

export default function MailContainerModal({ item }) {

    const { t, i18n } = useTranslation();

    const [claimIsLoading, setClaimIsLoading] = useState(false)
    const queryClient = useQueryClient()
    const mailContainerModalClaimMutation = useMutation((id: number) => claim(id), {
        onSuccess: (data) => {
            if (data.ok) {
                queryClient.invalidateQueries(['userLoot']).then(() => {
                    queryClient.invalidateQueries(['userHeroes']).then(() => {
                        setClaimIsLoading(false)
                        const myModalId = document.getElementById(`mailModal${item.id}`);
                        const myModal = bootstrap.Modal.getInstance(myModalId);
                        myModal.hide();
                        queryClient.invalidateQueries(['userMessage']).then(async () => {
                            await initDataUserNotification()
                        })
                    })
                })
            }
        },
        onError: () => {
            ToastError(t("toast.toastError"))
        }
    })
    const mailContainerModalClaimOnClick = () => {
        if (claimIsLoading) return

        setClaimIsLoading(true)

        mailContainerModalClaimMutation.mutate(item.id)
    }

    return (
        <div className="modal fade" id={`mailModal${item.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }} >
            <div className="modal-dialog modal-dialog-centered">
                <div className='modal-content bg-transparent p-0 m-0 border-0'>
                    <div className="mail-container-view-modal d-flex flex-column align-items-center">
                        <div className='h-100 w-100 d-flex flex-column align-items-center'>
                            <div className='mail-container-view-message d-flex justify-content-between w-100 position-relative'>
                                <div className='mail-container-view-message-envelope d-flex align-items-center'>
                                    <svg width="36" height="31" viewBox="0 0 36 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.59187 4.5H34.3124C35.2444 4.5 35.9999 5.25551 35.9999 6.1875V28.783C35.9999 29.6622 35.2872 30.3749 34.408 30.3749H1.59187C0.712757 30.3749 0 29.6622 0 28.783V6.09187C0 5.21269 0.712757 4.5 1.59187 4.5Z" fill="#FFCB5B" />
                                        <path d="M35.4374 7.07055L19.6875 15.9412C18.6605 16.5185 17.4069 16.5185 16.38 15.9412L0.562509 7.07055C0.213056 6.86883 -0.00160811 6.4954 9.07334e-06 6.09187C9.07334e-06 5.21276 0.712696 4.5 1.59188 4.5H34.4081C35.2872 4.5 36 5.21269 36 6.09187C36.0015 6.4954 35.7869 6.86883 35.4374 7.07055Z" fill="#FFDB6F" />
                                        <path d="M35.9999 6.13135C35.9878 6.52074 35.7749 6.8761 35.4374 7.07072L19.6875 15.9414C18.6605 16.5187 17.4069 16.5187 16.38 15.9414L0.562502 7.07072C0.225002 6.8761 0.0121663 6.52074 2.23627e-06 6.13135V6.1876V7.3126C-0.000771201 7.70128 0.199127 8.06283 0.528752 8.26885L16.2112 18.0676C17.2934 18.6854 17.9723 19.8255 17.9999 21.0713V24.7107C17.9999 26.5964 16.4713 28.1251 14.5856 28.1251H5.06257C3.81987 28.1251 2.81257 29.1325 2.81257 30.3751H34.4081C35.2872 30.3751 36 29.6624 36 28.7832V6.18767C36 6.18767 35.9999 6.15392 35.9999 6.13135Z" fill="#F7B84E" />
                                        <path d="M14.9007 11.8856L10.4682 9.42188C9.92448 9.12122 9.72746 8.43673 10.0281 7.893C10.2294 7.52907 10.6149 7.30582 11.0307 7.3125H11.2501C11.5607 7.3125 11.8126 7.06064 11.8126 6.75C11.8126 6.43936 11.5607 6.1875 11.2501 6.1875H4.50015C4.29843 6.18673 4.11168 6.29403 4.01078 6.46875C3.8601 6.74044 3.95812 7.08279 4.22973 7.23354C4.22987 7.23361 4.23001 7.23368 4.23015 7.23375L14.3551 12.8588C14.6043 13.0443 14.9567 12.9927 15.1422 12.7435C15.3278 12.4944 15.2762 12.1419 15.027 11.9564C14.988 11.9275 14.9456 11.9037 14.9007 11.8856Z" fill="#FFE278" />
                                        <path d="M13.5 6.1875H16.875C17.1856 6.1875 17.4375 6.43936 17.4375 6.75C17.4375 7.06064 17.1856 7.3125 16.875 7.3125H13.5C13.1894 7.3125 12.9375 7.06064 12.9375 6.75C12.9375 6.43936 13.1894 6.1875 13.5 6.1875Z" fill="#FFE278" />
                                        <path d="M27 11.8123V8.43725C27 7.8159 27.5036 7.31226 28.125 7.31226C28.7464 7.31226 29.25 7.8159 29.25 8.43725V10.5466L30.375 9.91663V8.43725C30.375 7.19455 29.3676 6.18726 28.125 6.18726C26.8824 6.18726 25.875 7.19462 25.875 8.43725V12.4479L27 11.8123Z" fill="#F7B84E" />
                                        <path d="M32.0625 4.5H31.5V9.28125L32.625 8.65125V5.0625C32.6249 4.75179 32.3731 4.5 32.0625 4.5Z" fill="#F7B84E" />
                                        <path d="M19.6537 15.9469C18.6267 16.5242 17.3731 16.5242 16.3462 15.9469L0.596191 7.09889C0.65434 7.15387 0.716426 7.20464 0.781816 7.25076L16.2056 16.8133C17.2959 17.4903 18.6758 17.4903 19.7661 16.8133L25.8749 13.0389V13.9445C25.8494 15.6693 27.1003 17.1484 28.8055 17.4095C30.6532 17.6549 32.3501 16.3559 32.5955 14.5083C32.6151 14.3605 32.6249 14.2116 32.6248 14.0626V9.47264C32.624 9.08395 32.8239 8.7224 33.1535 8.51639L35.201 7.24514C35.2664 7.19901 35.3284 7.14825 35.3867 7.09326L19.6537 15.9469ZM31.4999 14.0626C31.5062 15.3052 30.5038 16.3177 29.2612 16.3239C29.1823 16.3242 29.1035 16.3205 29.0249 16.3126C27.8474 16.1631 26.9732 15.1481 26.9999 13.9614V12.9658C26.9992 12.5771 27.1991 12.2155 27.5287 12.0095L29.2499 10.9464V12.3751C29.2499 12.6858 29.5018 12.9376 29.8124 12.9376C30.1231 12.9376 30.3749 12.6858 30.3749 12.3751V10.8733C30.3742 10.4846 30.5741 10.123 30.9037 9.91701L31.4999 9.56264V14.0626Z" fill="#F19920" />
                                        <path d="M28.6875 0C26.8236 0 25.3125 1.51101 25.3125 3.375V4.5H26.4375V3.37507C26.4375 2.13237 27.4449 1.12507 28.6875 1.12507C29.9301 1.12507 30.9375 2.13244 30.9375 3.37507V13.5001C30.9375 14.7428 29.9301 15.7501 28.6875 15.7501C27.4449 15.7501 26.4375 14.7427 26.4375 13.5001V7.87507C26.4375 7.25372 26.9411 6.75007 27.5625 6.75007C28.1839 6.75007 28.6875 7.25372 28.6875 7.87507V11.8126C28.6875 12.1232 28.9394 12.3751 29.25 12.3751C29.5606 12.3751 29.8125 12.1232 29.8125 11.8126V7.87507C29.8125 6.63236 28.8051 5.62507 27.5625 5.62507C26.3199 5.62507 25.3125 6.63244 25.3125 7.87507V13.5001C25.3125 15.364 26.8235 16.8751 28.6875 16.8751C30.5515 16.8751 32.0625 15.364 32.0625 13.5001V3.37507C32.0625 1.51109 30.5514 0 28.6875 0Z" fill="#A35425" />
                                    </svg>
                                </div>
                                <div className='mail-container-view-message-title w-100 d-flex align-items-center'>
                                    <div className='d-flex flex-column'>
                                        <div className='mail-container-view-message-subject'>
                                            {i18n.language === 'en' &&
                                                (item.messageTemplate.titleEn)
                                            }
                                            {i18n.language === 'ru' &&
                                                (item.messageTemplate.titleRu)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mail-container-view-body d-flex w-100 position-relative'>
                                <div className='mail-container-view-body-text d-flex'>
                                    {i18n.language === 'en' &&
                                        (item.messageTemplate.textEn)
                                    }
                                    {i18n.language === 'ru' &&
                                        (item.messageTemplate.textRu)
                                    }
                                </div>
                            </div>

                            <div className='mail-container-view-attach d-flex w-100 align-items-center' style={{ overflowX: 'auto', paddingRight: '8px' }}>


                                {item.UserMessageReward.map((reward, index) => {
                                    return (
                                        <div className='mail-container-view-attach-item d-flex justify-content-center position-relative'>
                                            <div className='d-flex justify-content-center align-items-center'>

                                                {reward.type === "OLDCOIN" &&
                                                    <img src={`img/loot/old-coins.png`} height='auto' width='60px' />
                                                }

                                                {reward.type === "CRYSTAL" &&
                                                    <img src={`img/chests/smallChest/crystal.png`} height='auto' width='60px' />
                                                }

                                                {reward.type === "HERO" &&
                                                    <img src={`/img/heroes/${reward.hero.pathImage}.jpg`} height='60px' width='60px' />
                                                }

                                                {reward.type === "FRAGMENT" &&
                                                    <img src={`/img/heroes/${reward.hero.pathImageFragment}.png`} height='60px' width='auto' />
                                                }

                                                {reward.type === "ENERGYBOTTLE" &&
                                                    <img src={`/img/loot/energy-bottles.png`} height='60px' width='auto' />
                                                }


                                            </div>
                                            <div className='mail-container-view-attach-count text-white d-flex justify-content-center align-items-center position-absolute'>
                                                {numberFormat(reward.count)}
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>

                            {item.status === "NEW" &&
                                <div className='mail-container-view-button d-flex w-100 justify-content-end'>
                                    <div className='d-flex position-relative' onClick={mailContainerModalClaimOnClick}>
                                        <div className='d-flex justify-content-center'>
                                            <svg width="116" height="34" viewBox="0 0 116 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M108.781 17.1206L92.6577 32.9999H19.5089L1 17.1206L19.5089 1.23853H92.6577L108.781 17.1206Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                                <path d="M114.41 16.882L98.2866 32.7614H25.1378L6.62891 16.882L25.1378 1H98.2866L114.41 16.882Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                            </svg>
                                            <div className={'position-absolute top-50 start-50 translate-middle'}>
                                                {!claimIsLoading &&
                                                    (t("mailContainer.take"))
                                                }
                                                {claimIsLoading &&
                                                    <DataFetchLoader width={17} height={17} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
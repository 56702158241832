//@ts-nocheck
import './EventContainerModal.scss'
import { useTranslation } from "react-i18next"
import { getActive } from '../../../api/eventBattleReward'
import { useQuery } from 'react-query'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../../DataFetchError/DataFetchError'

export default function EventContainerModal() {

    const { t, i18n } = useTranslation();

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['eventBattleReward'],
        () => getActive(),
        {
            keepPreviousData: true,
        }
    )
    
    return (
        <div className="modal fade overflow-hidden" id="eventModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
            {isSuccess &&
                <>
                    <div className={'modal-dialog modal-dialog-centered h-100'}>
                        <div className="modal-content event-container-modal w-100 overflow-auto">
                            <div className='text-center text-white'>
                                {t("eventRaiting.rewards")}
                            </div>
                            <div className='event-container-modal-desc d-flex flex-column'>
                                <div>{t("eventRaiting.desc_1")}</div>
                                <div>{t("eventRaiting.desc_2")}</div>
                                <div>{t("eventRaiting.desc_3")}</div>
                            </div>
                            <div className='event-container-modal-reward d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center w-100'>
                                        <div className='event-container-modal-reward-hero d-flex justify-content-center'>
                                            <img src={`/img/heroes/${data.eventBattleReward.topHero.pathImage}.jpg`} width={'auto'} height={'auto'} />
                                        </div>
                                        <div className='event-container-modal-reward-text d-flex'>
                                            <div>
                                                <span style={{ color: '#ffffff' }}>#1</span>
                                                {i18n.language === 'en' &&
                                                    (data.eventBattleReward.topHero.nameEn)
                                                }
                                                {i18n.language === 'ru' &&
                                                    (data.eventBattleReward.topHero.nameRu)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.fragments")}</div>
                                <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center'>
                                    <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
                                        <img src={`/img/heroes/${data.eventBattleReward.topHero.pathImageFragment}.png`} width={'auto'} height={'60px'} />
                                    </div>

                                    <div className='event-container-modal-reward-text d-flex flex-column'>
                                        <div><span style={{ color: '#ffffff' }}>#2</span> x400 шт.</div>
                                        <div><span style={{ color: '#ffffff' }}>#3</span> x300 шт.</div>
                                        <div><span style={{ color: '#ffffff' }}>#4-10</span> x200 шт.</div>
                                    </div>
                                    <div className='event-container-modal-reward-text d-flex flex-column'>
                                        <div><span style={{ color: '#ffffff' }}>#11-25</span> x100 шт.</div>
                                        <div><span style={{ color: '#ffffff' }}>#26-50</span> x25 шт.</div>
                                    </div>
                                </div>
                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.crystals")}</div>
                                <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center'>
                                    <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
                                        <img src='/img/loot/daily-reward-crystal-icon.png' width={'auto'} height={'40px'} />
                                    </div>

                                    <div className='event-container-modal-reward-text d-flex flex-column'>
                                        <div><span style={{ color: '#ffffff' }}>#1</span> 30 000 шт.</div>
                                        <div><span style={{ color: '#ffffff' }}>#2</span> 15 000 шт.</div>
                                        <div><span style={{ color: '#ffffff' }}>#3</span> 10 000 шт.</div>
                                    </div>
                                    <div className='event-container-modal-reward-text d-flex flex-column'>
                                        <div><span style={{ color: '#ffffff' }}>#4-10</span> 5 000 шт.</div>
                                        <div><span style={{ color: '#ffffff' }}>#11-25</span> 1 000 шт.</div>
                                        <div><span style={{ color: '#ffffff' }}>#26-100</span> 500 шт.</div>
                                    </div>
                                </div>
                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.oldcoins")}</div>
                                <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center'>
                                    <div className='event-container-modal-reward-image d-flex justify-content-center position-relative'>
                                        <img src='/img/loot/old-coins.png' width={'auto'} height={'40px'} />
                                    </div>
                                    <div className='event-container-modal-reward-text d-flex flex-column'>
                                        <div><span style={{ color: '#ffffff' }}>#1-25</span> x150%</div>
                                        <div><span style={{ color: '#ffffff' }}>#26-50</span> x100%</div>
                                    </div>
                                    <div className='event-container-modal-reward-text d-flex flex-column'>
                                        <div><span style={{ color: '#ffffff' }}>#51-100</span> x50%</div>
                                        <div><span style={{ color: '#ffffff' }}>#101+</span> x25%</div>
                                    </div>
                                </div>
                                <div className='event-container-modal-reward-name d-flex w-100'>
                                    {i18n.language === 'en' &&
                                        (data.eventBattleReward.guaranteedHero.nameEn)
                                    }
                                    {i18n.language === 'ru' &&
                                        (data.eventBattleReward.guaranteedHero.nameRu)
                                    }
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='event-container-modal-reward-item d-flex justify-content-between align-items-center w-100'>
                                        <div className='event-container-modal-reward-hero d-flex justify-content-center position-relative'>
                                            <img src={`/img/heroes/${data.eventBattleReward.guaranteedHero.pathImage}.jpg`} width={'auto'} height={'auto'} />
                                        </div>
                                        <div className='event-container-modal-reward-text d-flex'>
                                            <div>7 500 {t("eventRaiting.oldcoin")}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='event-container-modal-reward-name d-flex w-100'>{t("eventRaiting.rewards")}</div>
                                <div className='event-container-modal-reward-item d-flex flex-column'>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>100 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>500 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>400 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>1 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>1 500 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>2 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>5 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>3 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>12 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>8 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>21 500 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>12 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>34 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>15 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>55 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>20 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>130 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>25 000 {t("eventRaiting.crystal")}</div>
                                    </div>
                                </div>
                                <div className='event-container-modal-reward-name d-flex w-100'></div>
                                <div className='event-container-modal-reward-item d-flex flex-column'>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>200 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>10 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>500 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>25 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>2 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>25 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>7 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>40 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>18 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>50 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-no d-flex justify-content-between w-100'>
                                        <div className='d-flex'>40 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>100 {t("eventRaiting.bottle")}</div>
                                    </div>
                                    <div className='event-container-modal-reward-grey d-flex justify-content-between w-100'>
                                        <div className='d-flex'>100 000 {t("eventRaiting.oldcoin")}</div>
                                        <div className='d-flex'>500 {t("eventRaiting.bottle")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("eventRaiting.dataFetchError")} />
            }
        </div>
    )
}
//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

export const eventPrintingSlice = createSlice({
    name: "eventPrinting",
    initialState: {
        seal: null
    },
    reducers: {
        setEventPrintingSeal: (state, action) => {
            state.seal = action.payload;
        },
    },
})

export const { setEventPrintingSeal } = eventPrintingSlice.actions

export default eventPrintingSlice.reducer
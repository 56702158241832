//@ts-nocheck
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import ToastSuccess from "../Toasts/ToastSuccess/ToastSuccess"
import './ShopHeroStarsItemModal.scss'
import DataFetchLoader from "../../../DataFetchLoader/DataFetchLoader"
import { numberFormat } from "../../../../utils/NumberFormat"
import { initDataUserHeroesDamage } from "../../../../utils/initData"
import { buy } from "../../../../api/shopHeroStars"

// import { initUtils } from '@telegram-apps/sdk';
// const utils = initUtils();

import { initInvoice } from '@telegram-apps/sdk';
const invoice = initInvoice();

export default function ShopHeroStarsItemModal({ userHero, hero, price }) {

    const { t, i18n } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [heroIsBuy, setHeroIsBuy] = useState(false)

    const queryClient = useQueryClient()
    const shopHeroStarsItemModalBuyMutation = useMutation(
        (heroId: number) => buy(heroId),
        {
            onSuccess: (data) => {

                if (data.data.invoice.ok === true) {
                    invoice.open(data.data.invoice.result, 'url').then((status) => {
                        if (status === 'paid') {
                            setIsLoading(false);
                            setHeroIsBuy(true);
                            const myModalId = document.getElementById(
                                "shopHeroStarsItemModal" + hero.id
                            );
                            myModalId?.setAttribute("data-bs-backdrop", "static");
                            var myModal = bootstrap.Modal.getInstance(myModalId, {
                                backdrop: "static",
                            });
                            myModal.dispose();

                            var myModal = new bootstrap.Modal(myModalId, {
                                backdrop: "static",
                            });
                            myModal.show();
                        } else {
                            setIsLoading(false);    
                        }
                    })
                }

            },
        }
    );

    const shopHeroStarsItemModalBuyOnClick = () => {
        if (userHero !== null) return
        if (isLoading) return

        setIsLoading(true)

        shopHeroStarsItemModalBuyMutation.mutate(hero.id)
    };

    const shopHeroStarsItemCloseOnClick = () => {
        if (isLoading) return
        if (!heroIsBuy) return

        setIsLoading(true);
        queryClient.invalidateQueries(["shopHero"]).then(() => {
            const myModalId = document.getElementById("shopHeroStarsItemModal" + hero.id)
            myModalId?.setAttribute("data-bs-backdrop", "true")
            const myModal = bootstrap.Modal.getInstance(myModalId)
            myModal.hide()
            initDataUserHeroesDamage()
            setIsLoading(false)
            setHeroIsBuy(false)
        });
    };

    return (
        <div class="modal fade" id={"shopHeroStarsItemModal" + hero.id} tabindex="-1" aria-labelledby={"shopHeroStarsItemModal" + hero.id} aria-hidden="true" style={{ backdropFilter: "blur(5px)" }}>
            <div className="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-transparent p-0 m-0 border-0">
                    <div className="shop-hero-stars-item-modal w-100 d-flex">
                        <div className="w-100 d-flex flex-column justify-content-between" style={{ padding: "8px" }}>
                            {!heroIsBuy && (
                                <>
                                    <div className="shop-hero-stars-item-modal-name">
                                        {i18n.language === "en" && hero.nameEn}
                                        {i18n.language === "ru" && hero.nameRu}
                                    </div>
                                    <div className="shop-hero-stars-item-modal-info-container w-100 d-flex flex-column align-items-center">
                                        <div>
                                            <div className="shop-hero-stars-item-modal-info  w-100">
                                                {t("heroDetailsModal.basePrice")}{" "}
                                                <span>{numberFormat(hero.basePrice)}</span>
                                            </div>
                                            <div className="shop-hero-stars-item-modal-info  w-100">
                                                {t("heroDetailsModal.cpc")}{" "}
                                                <span>{numberFormat(hero.baseDamage)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shop-hero-stars-item-modal-info-button-container w-100 d-flex justify-content-center position-relative">
                                        <div className="shop-hero-stars-item-modal-btn position-absolute top-50 start-50 translate-middle">
                                            {!isLoading && (
                                                <div className="d-flex align-items-center">
                                                    <div className={`shop-hero-stars-item-modal-price d-flex align-items-center`}>
                                                        <img src={`/img/loot/star-icon.png`} height='20px' style={{marginRight: "3px"}}/>
                                                        {price}
                                                    </div>
                                                </div>
                                            )}
                                            {isLoading && (
                                                <div className="position-absolute top-50 start-50 translate-middle">
                                                    <DataFetchLoader
                                                        width={21}
                                                        height={21}
                                                        color={"#B28E4C"}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <svg
                                            onClick={shopHeroStarsItemModalBuyOnClick}
                                            width="123"
                                            height="38"
                                            viewBox="0 0 123 38"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ zIndex: "10" }}
                                        >
                                            <path
                                                d="M122.316 18.8673L105.211 36.7316H27.6076L7.97168 18.8673L27.6076 1H105.211L122.316 18.8673Z"
                                                stroke="#B28E4C"
                                                stroke-miterlimit="10"
                                            />
                                            <path
                                                d="M116.344 19.1357L99.2392 37H21.636L2 19.1357L21.636 1.26843H99.2392L116.344 19.1357Z"
                                                stroke="#B28E4C"
                                                stroke-width="2"
                                                stroke-miterlimit="10"
                                            />
                                        </svg>
                                    </div>
                                </>
                            )}

                            {heroIsBuy && (
                                <>
                                    <div className="shop-hero-stars-item-modal-buy-title">
                                        {t("heroDetailsModal.buyTitle")}
                                    </div>

                                    <div className="shop-hero-stars-item-modal-buy-name">
                                        {i18n.language === "en" && hero.nameEn}
                                        {i18n.language === "ru" && hero.nameRu}
                                    </div>

                                    <div className="shop-hero-stars-item-modal-info-button-container w-100 d-flex justify-content-center position-relative">
                                        <div className="shop-hero-stars-item-modal-btn position-absolute top-50 start-50 translate-middle">
                                            {!isLoading && (
                                                <div className="d-flex align-items-center">
                                                    <div
                                                        id={"shopHeroPriceDetails" + hero.id}
                                                        className={`shop-hero-stars-item-modal-buy-continue`}
                                                    >
                                                        {t("heroDetailsModal.continue")}
                                                    </div>
                                                </div>
                                            )}
                                            {isLoading && (
                                                <div className="position-absolute top-50 start-50 translate-middle">
                                                    <DataFetchLoader
                                                        width={21}
                                                        height={21}
                                                        color={"#B28E4C"}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <svg
                                            onClick={shopHeroStarsItemCloseOnClick}
                                            width="123"
                                            height="38"
                                            viewBox="0 0 123 38"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ zIndex: "10" }}
                                        >
                                            <path
                                                d="M122.316 18.8673L105.211 36.7316H27.6076L7.97168 18.8673L27.6076 1H105.211L122.316 18.8673Z"
                                                stroke="#B28E4C"
                                                stroke-miterlimit="10"
                                            />
                                            <path
                                                d="M116.344 19.1357L99.2392 37H21.636L2 19.1357L21.636 1.26843H99.2392L116.344 19.1357Z"
                                                stroke="#B28E4C"
                                                stroke-width="2"
                                                stroke-miterlimit="10"
                                            />
                                        </svg>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="d-flex align-items-center">
                            <img
                                className={`shop-hero-stars-item-modal-image ${heroIsBuy ? "shop-hero-stars-item-modal-buy-image" : ""}`}
                                src={`/img/heroes/${hero.pathImage}.jpg`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
//@ts-nocheck

import { getInitData } from '../../../utils/telegram'
import './EventContainerClansItems.scss'

export default function EventContainerClansItems({ usersOldCoinEvent }) {

    const initDataId = getInitData()?.user.id

    return (
        <div style={{ paddingTop: '5px' }}>
            {usersOldCoinEvent.map((item, index) => {
                return (
                    <>
                        <div key={index} className={`rating-container-item-gold ${index + 1 > 3 ? 'rating-container-item-gold-opacity' : ''}  w-100 d-flex justify-content-between align-items-center`}>

                            <div className='d-flex'>
                                <img style={{ borderRadius: '4px', marginRight: '12px' }} height={30} src='/img/clan/flags/default.png' width={'auto'} />
                                <div className='rating-container-item-user d-flex align-items-center'>

                                    {((index + 1 + ". " + item.clanName).substring(0, 30))}

                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='rating-container-item-count' style={{ marginRight: '6px' }}>{item.count}</div>
                                <img src={`img/loot/old-coins.png`} height='18px' width='auto' />
                            </div>
                        </div>
                    </>
                )
            })
            }
        </div>
    )
}

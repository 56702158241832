//@ts-nocheck

import store from '../redux/store'

import { getStartParamRef } from '../utils/telegram'

import { upsertUser } from '../api/user'
import { getUserLoot } from '../api/userLoot'
import { getUserHeroes } from '../api/userHero'

import { setUserLootCountGold } from '../redux/userLootGoldSlice/userLootGoldSlice'
import { setUserLootCountCrystal } from '../redux/userLootCrystalSlice/userLootCrystalSlice'
import { setUserHeroesDamage, setUserHeroesDamageDPC, setUserHeroesDamageDPS, setUserHeroesDamageIPS } from '../redux/userHeroesDamageSlice/userHeroesDamageSlice'
import { setUserEnergyCount, setUserEnergyCountStorageFirstTime } from '../redux/userEnergyCountSlice/userEnergyCountSlice'

import { setUserQuestCount } from '../redux/userQuestCountSlice/userQuestCountSlice'
import { getUserDailyRewards } from '../api/userDailyReward'
import { getUserQuestSocial } from '../api/userQuestSocial'
import { setUserApiError } from '../redux/Error/userApiError/userApiErrorSlice'
import BigNumber from "bignumber.js";
import { get } from '../api/userEnergy'
import { getByUserId } from '../api/UserNotification'
import { setUserNotifications } from '../redux/userNotificationsSlice/userNotificationsSlice'
import { setUserLootCountEnergyBottle } from '../redux/userLootEnergyBottleSlice/userLootEnergyBottleSlice'
import { setUserLootCountOldCoin } from '../redux/userLootOldCoinSlice/userLootOldCoinSlice'
import { setUserBan } from '../redux/userBanSlice/userBanSlice'
import { setIsFirstTime } from '../redux/userSlice/userSlice'
import axios from 'axios'
import { setUserHeroBonusEVENTOLDCOIN } from '../redux/userHeroBonusSlice/userHeroBonusSlice'

export const startInitData = async () => {
  try {
    const userFetch = await upsertUser(getStartParamRef())
    if (userFetch.status !== 200) {
      return
    }
    if (!userFetch.data.upsertUser) {
      return
    }

    if (userFetch.data.isBan) {
      store.dispatch(setUserBan(true))
      return
    }

    if (userFetch.data.isFirstTime) {
      store.dispatch(setIsFirstTime(true))
    }

    axios.defaults.headers.common['hashUuidv4'] = userFetch.data.hashUuidv4

    await initDataUserLootCount()
    await initDataUserHeroesDamage()
    await initDataUserEnergyCount()
    await initDataUserQuestCount()
    await initDataUserNotification()
  } catch {
    store.dispatch(setUserApiError(true))
  }
}

const initDataUserLootCount = async () => {
  const userLoot = await getUserLoot()
  for (const userLootEl of userLoot.data) {
    if (userLootEl.name === "Gold")
      store.dispatch(setUserLootCountGold(userLootEl.count))
    if (userLootEl.name === "Crystal")
      store.dispatch(setUserLootCountCrystal(userLootEl.count))
    if (userLootEl.name === "OldCoin")
      store.dispatch(setUserLootCountOldCoin(userLootEl.count))
    if (userLootEl.name === "EnergyBottle")
      store.dispatch(setUserLootCountEnergyBottle(userLootEl.count))
  }

  return true
}

export const initDataUserHeroesDamage = async () => {
  const data = await getUserHeroes();
  const userHeroesDamage = {
    DPC: BigInt(0),
    DPS: BigInt(0),
    IPS: BigInt(0),
    damage: BigInt(0)
  }

  const userHeroBonus = {
    EVENTOLDCOIN: 0,
    GOLD: 0
  }

  for (const element of data.userHeroes) {
    if (element.status !== "ACTIVE")
      continue

    if (element.level === 0)
      continue

    if (element.mine.inMine)
      continue

    for (const bonus of element.hero.heroBonus) {
      if (bonus.type === "GOLD") {
        userHeroBonus.GOLD += bonus.value
      }
    }

    if (element.hero.damageType === "DPC")
      userHeroesDamage.DPC += BigInt(element.level) * BigInt(element.hero.baseDamage) * BigInt(element.hero.damageRate)

    if (element.hero.damageType === "DPS")
      userHeroesDamage.DPS += BigInt(element.level) * BigInt(element.hero.baseDamage) * BigInt(element.hero.damageRate)

    userHeroesDamage.damage += BigInt(Math.ceil((element.hero.damage * element.level) * 1.07).toString())
  }

  let userHeroesDamageDPS = new BigNumber(userHeroesDamage.DPS.toString())
  if (userHeroBonus.GOLD !== 0) {
    userHeroesDamageDPS = userHeroesDamageDPS.plus(userHeroesDamageDPS.dividedBy(new BigNumber(100)).multipliedBy(new BigNumber(userHeroBonus.GOLD.toString())).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN)).toString()
  }

  store.dispatch(setUserHeroesDamageDPC(userHeroesDamage.DPC.toString()))
  store.dispatch(setUserHeroesDamageDPS(userHeroesDamageDPS.toString()))
  store.dispatch(setUserHeroesDamage(userHeroesDamage.damage.toString()))

  for (const element of data.userHeroes) {
    if (element.status !== "ACTIVE")
      continue

    if (element.level === 0)
      continue

    if (element.mine.inMine)
      continue

    const ipsKF = 0.15
    const ips = element.level === 1 ? new BigNumber(element.hero.baseDamage).multipliedBy(ipsKF).dividedBy(2).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN).toString() : new BigNumber(element.hero.baseDamage).multipliedBy(new BigNumber(element.level - 1) * new BigNumber(ipsKF)).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN).toString()
    userHeroesDamage.IPS += BigInt(ips)
  }


  let userHeroesDamageIPS = new BigNumber(userHeroesDamage.IPS.toString())
  if (userHeroBonus.GOLD !== 0) {
    userHeroesDamageIPS = userHeroesDamageIPS.plus(userHeroesDamageIPS.dividedBy(new BigNumber(100)).multipliedBy(new BigNumber(userHeroBonus.GOLD.toString())).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN)).toString()
  }
  store.dispatch(setUserHeroesDamageIPS(userHeroesDamageIPS.toString()))

  for (const element of data.userHeroes) {
    if (element.status !== "ACTIVE")
      continue

    if (element.level === 0)
      continue

    if (element.mine.inMine)
      continue

    for (const bonus of element.hero.heroBonus) {
      if (bonus.type === "EVENTOLDCOIN") {
        userHeroBonus.EVENTOLDCOIN += bonus.value
      }
    }
  }
  store.dispatch(setUserHeroBonusEVENTOLDCOIN(userHeroBonus.EVENTOLDCOIN))

  return true
}

const initDataUserEnergyCount = async () => {
  const data = await get()
  store.dispatch(setUserEnergyCount(data.count))

  return true
}

export const initDataUserQuestCount = async () => {
  let userQuestCount = 0

  const dataUserDailyRewards = await getUserDailyRewards()
  if (dataUserDailyRewards.data.timeAwait == 0)
    userQuestCount++

  const dataUserQuestSocial = await getUserQuestSocial()
  let noCompletedUserQuestSocial = 0
  for (const element of dataUserQuestSocial.questSocial) {
    if (!element.active) continue
    if (!element.completed)
      noCompletedUserQuestSocial++
  }

  store.dispatch(setUserQuestCount(userQuestCount + noCompletedUserQuestSocial))

  return true
}

export const initDataUserNotification = async () => {

  const userNotification = await getByUserId()

  store.dispatch(setUserNotifications(userNotification))

  return true
}
//@ts-nocheck

import { useTranslation } from "react-i18next";
import "./HeroDetailsModal.scss";
import { numberFormat } from "../../utils/NumberFormat";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import DataFetchLoader from "../DataFetchLoader/DataFetchLoader";
import { buy } from "../../api/shopHero";
import { decrementUserLootCountCrystal } from "../../redux/userLootCrystalSlice/userLootCrystalSlice";
import { initDataUserHeroesDamage } from "../../utils/initData";
import ToastSuccess from "../Toasts/ToastSuccess/ToastSuccess";

export default function HeroDetailsModal({ userHero, hero, price }) {
  const { t, i18n } = useTranslation();

  const countUserLootCrystal = useSelector(
    (state) => state.userLootCrystal.count
  );

  const [isLoading, setIsLoading] = useState(false);
  const [heroIsBuy, setHeroIsBuy] = useState(false);

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const shopHeroContainerItemBuyMutation = useMutation(
    (heroId: number) => buy(heroId),
    {
      onSuccess: (data) => {
        if (data.data.isBuy === true) {
          dispatch(decrementUserLootCountCrystal(price));
          setIsLoading(false);
          setHeroIsBuy(true);
          const myModalId = document.getElementById(
            "heroDetailsModal" + hero.id
          );
          myModalId?.setAttribute("data-bs-backdrop", "static");
          var myModal = bootstrap.Modal.getInstance(myModalId, {
            backdrop: "static",
          });
          myModal.dispose();

          var myModal = new bootstrap.Modal(myModalId, {
            backdrop: "static",
          });
          myModal.show();
        } else {
          ToastError(data.data.error);
        }
      },
    }
  );

  const heroDetailsModalBuyOnClick = () => {
    if (userHero !== null) return;

    if (BigInt(countUserLootCrystal) < BigInt(price)) {
      const shopHeroPriceElem = document.getElementById(
        "shopHeroPriceDetails" + hero.id
      );
      shopHeroPriceElem.classList.toggle("hero-details-modal-price-animate");
      setTimeout(() => {
        shopHeroPriceElem.classList.remove("hero-details-modal-price-animate");
      }, 300);

      return;
    }

    if (isLoading) return;

    setIsLoading(true);

    shopHeroContainerItemBuyMutation.mutate(hero.id);
  };
  const heroDetailsModalCloseOnClick = () => {
    if (isLoading) return;
    if (!heroIsBuy) return;

    setIsLoading(true);
    queryClient.invalidateQueries(["shopHero"]).then(() => {
      const myModalId = document.getElementById("heroDetailsModal" + hero.id);
      myModalId?.setAttribute("data-bs-backdrop", "true");
      const myModal = bootstrap.Modal.getInstance(myModalId);
      myModal.hide();
      initDataUserHeroesDamage();
      setIsLoading(false);
      setHeroIsBuy(false);
    });
  };

  return (
    <div
      class="modal fade"
      id={"heroDetailsModal" + hero.id}
      tabindex="-1"
      aria-labelledby={"heroDetailsModal" + hero.id}
      aria-hidden="true"
      style={{ backdropFilter: "blur(5px)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-transparent p-0 m-0 border-0">
          <div className="hero-details-modal w-100 d-flex">
            <div
              className="w-100 d-flex flex-column justify-content-between"
              style={{ padding: "8px" }}
            >
              {!heroIsBuy && (
                <>
                  <div className="hero-details-modal-name">
                    {i18n.language === "en" && hero.nameEn}
                    {i18n.language === "ru" && hero.nameRu}
                  </div>
                  <div className="hero-details-modal-info-container w-100 d-flex flex-column align-items-center">
                    <div>
                      <div className="hero-details-modal-info  w-100">
                        {t("heroDetailsModal.basePrice")}{" "}
                        <span>{numberFormat(hero.basePrice)}</span>
                      </div>
                      <div className="hero-details-modal-info  w-100">
                        {t("heroDetailsModal.cpc")}{" "}
                        <span>{numberFormat(hero.baseDamage)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="hero-details-modal-info-button-container w-100 d-flex justify-content-center position-relative">
                    <div className="hero-details-modal-btn position-absolute top-50 start-50 translate-middle">
                      {!isLoading && (
                        <div className="d-flex align-items-center">
                          <img
                            width={10.5}
                            height={16}
                            src="/img/loot/crystal-icon.png"
                            style={{ marginRight: "4px" }}
                          />
                          <div
                            id={"shopHeroPriceDetails" + hero.id}
                            className={`hero-details-modal-price ${
                              BigInt(countUserLootCrystal) < BigInt(price) &&
                              !isLoading
                                ? "hero-details-modal-price-red"
                                : ""
                            }`}
                          >
                            {price}
                          </div>
                        </div>
                      )}
                      {isLoading && (
                        <div className="position-absolute top-50 start-50 translate-middle">
                          <DataFetchLoader
                            width={21}
                            height={21}
                            color={"#B28E4C"}
                          />
                        </div>
                      )}
                    </div>
                    <svg
                      onClick={heroDetailsModalBuyOnClick}
                      width="123"
                      height="38"
                      viewBox="0 0 123 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ zIndex: "10" }}
                    >
                      <path
                        d="M122.316 18.8673L105.211 36.7316H27.6076L7.97168 18.8673L27.6076 1H105.211L122.316 18.8673Z"
                        stroke="#B28E4C"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M116.344 19.1357L99.2392 37H21.636L2 19.1357L21.636 1.26843H99.2392L116.344 19.1357Z"
                        stroke="#B28E4C"
                        stroke-width="2"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </div>
                </>
              )}

              {heroIsBuy && (
                <>
                  <div className="hero-details-modal-buy-title">
                    {t("heroDetailsModal.buyTitle")}
                  </div>

                  <div className="hero-details-modal-buy-name">
                    {i18n.language === "en" && hero.nameEn}
                    {i18n.language === "ru" && hero.nameRu}
                  </div>

                  <div className="hero-details-modal-info-button-container w-100 d-flex justify-content-center position-relative">
                    <div className="hero-details-modal-btn position-absolute top-50 start-50 translate-middle">
                      {!isLoading && (
                        <div className="d-flex align-items-center">
                          <div
                            id={"shopHeroPriceDetails" + hero.id}
                            className={`hero-details-modal-buy-continue`}
                          >
                            {t("heroDetailsModal.continue")}
                          </div>
                        </div>
                      )}
                      {isLoading && (
                        <div className="position-absolute top-50 start-50 translate-middle">
                          <DataFetchLoader
                            width={21}
                            height={21}
                            color={"#B28E4C"}
                          />
                        </div>
                      )}
                    </div>
                    <svg
                      onClick={heroDetailsModalCloseOnClick}
                      width="123"
                      height="38"
                      viewBox="0 0 123 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ zIndex: "10" }}
                    >
                      <path
                        d="M122.316 18.8673L105.211 36.7316H27.6076L7.97168 18.8673L27.6076 1H105.211L122.316 18.8673Z"
                        stroke="#B28E4C"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M116.344 19.1357L99.2392 37H21.636L2 19.1357L21.636 1.26843H99.2392L116.344 19.1357Z"
                        stroke="#B28E4C"
                        stroke-width="2"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex align-items-center">
              <img
                className={`hero-details-modal-image ${heroIsBuy ? "hero-details-modal-buy-image" : ""}`}
                src={`/img/heroes/${hero.pathImage}.jpg`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//@ts-nocheck
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import './ShopHeroStarsItem.scss'
import ShopHeroStarsItemModal from './ShopHeroStarsItemModal/ShopHeroStarsItemModal';

export default function ShopHeroStarsItem({ shopHeroStars }) {

    const heroNameEn = shopHeroStars.hero.nameEn
    const heroNameRu = shopHeroStars.hero.nameRu
    const heroPathImg = shopHeroStars.hero.pathImage
    const heroPrice = shopHeroStars.price

    const { t, i18n } = useTranslation()

    const shopHeroStarsItemBuy = () => {
        const myModal = new bootstrap.Modal(document.getElementById('shopHeroStarsItemModal' + shopHeroStars.hero.id))
        myModal.show()
    }

    return (
        <>
            <div onClick={shopHeroStarsItemBuy} className='shop-hero-stars-item d-flex justify-content-between'>
                <div>
                    <div className='shop-hero-stars-item-name lh-1'>
                        {i18n.language === 'en' &&
                            (heroNameEn)
                        }
                        {i18n.language === 'ru' &&
                            (heroNameRu)
                        }
                    </div>
                    <div className='shop-hero-stars-item-click-details lh-1'>
                        {t("shopHeroStarsItem.click")}
                    </div>
                    <div className={`shop-hero-stars-item-price d-flex justify-content-start align-items-center gap-1`}>
                        <img src={`/img/loot/star-icon.png`} height='16px' />
                        {heroPrice}
                    </div>
                </div>
                <img src={`img/heroes/${heroPathImg}.jpg`} height='70px' className='shop-hero-stars-item-img' />
            </div>
            <ShopHeroStarsItemModal hero={shopHeroStars.hero} price={shopHeroStars.price} userHero={shopHeroStars.userHero}/>
        </>
    )
}
//@ts-nocheck
import { useTranslation } from 'react-i18next'
import './HeroLinesViewMine.scss'
import { getInitData } from '../../../utils/telegram'
import { MemoCountdown } from '../../MemoCountdown/MemoCountdown'

export default function HeroLinesViewMine({ index, userHero }) {

    const { t, i18n } = useTranslation()
    const initData = getInitData()

    const nameRu = userHero.hero.nameRu === "User" ? initData.user?.firstName + " " + initData.user?.lastName : userHero.hero.nameRu
    const nameEn = userHero.hero.nameRu === "User" ? initData.user?.firstName + " " + initData.user?.lastName : userHero.hero.nameEn
    const image = `/img/heroes/${userHero.hero.pathImage}.jpg`
    const rank = userHero.rank

    return (
        <div className={`position-relative w-100 border_box ${userHero.hero.type === "SHOP" ? "border_box_blue" : ""}`}>

            <img className='position-absolute top-50 end-0 translate-middle-y w-75 h-100' src='/img/background/hero-lines-view-mine.png'/>

            <div className={`w-100 h-100 line_box_unlocked ${userHero.hero.type === "SHOP" ? "border_box_blue" : ""}`}>
                <div className={'d-flex w-100 position-relative'}>
                    <div className='position-relative' style={{marginTop: '12px'}}>
                        <img src={image} className={'line_img'} style={{ filter: 'grayscale(100%)' }} />
                        {userHero.hero.id !== 1 &&
                            <div className={'position-absolute top-0 left-0'}>
                                <img src={`/img/ranks/rank${rank}.svg`} className={'line_img'} />
                            </div>
                        }
                    </div>
                    <div className={'ms-3 w-100'}>
                        <div className='d-flex flex-column justify-content-between w-100 h-100'>
                            <div>
                                <div className='hero-lines-view-unlocked-name'>
                                    {i18n.language === 'en' &&
                                        (nameEn)
                                    }
                                    {i18n.language === 'ru' &&
                                        (nameRu)
                                    }
                                </div>
                                <div className='hero-lines-view-mine-desc'>{t("heroLinesViewMine.desc")}</div>
                            </div>

                            <div className='w-100 d-flex justify-content-end'>
                                <div className='position-relative' style={{ width: '120px', height: '40px' }}>
                                    <svg width="120" height="40" viewBox="0 0 110 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M108.947 16.882L93.7424 32.7614H24.7618L7.30762 16.882L24.7618 1H93.7424L108.947 16.882Z" stroke="#B0B0B0" stroke-miterlimit="10" />
                                        <path d="M103.639 17.1207L88.4348 33H19.4542L2 17.1207L19.4542 1.23865H88.4348L103.639 17.1207Z" stroke="#B0B0B0" stroke-width="2" stroke-miterlimit="10" />
                                    </svg>
                                    <div className='hero-lines-view-mine-timer position-absolute translate-middle position-absolute top-50 start-50'>
                                        <MemoCountdown timeAwait={userHero.mine.timeAwait} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
//@ts-nocheck

import { useQuery } from 'react-query'
import { getActive } from '../../../api/comboStars'
import './ComboStarsModal.scss'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import ComboStarsModalContent from './ComboStarsModalContent/ComboStarsModalContent'
import ComboStarsFreeModalContent from './ComboStarsFreeModalContent/ComboStarsFreeModalContent'

export default function ComboStarsModal() {

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['comboStars'],
        () => getActive(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className="modal fade overflow-hidden" id="comboStars" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
            <div className={'modal-dialog modal-dialog-centered h-100 d-flex justify-content-center'}>
                <div className="modal-content bg-transparent p-0 m-0 border-0 d-flex align-items-center w-auto">
                    {isSuccess &&
                        <div className="p-0 m-0 combo-stars-modal">
                            <div className='w-100 h-100'>
                                {data.comboStars !== null &&
                                    <>
                                        {data.comboStars.price !== 0 &&
                                            <ComboStarsModalContent data={data} />
                                        }
                                        {data.comboStars.price === 0 &&
                                            <ComboStarsFreeModalContent data={data} />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }
                    {isLoading &&
                        <DataFetchLoader width={50} height={50} />
                    }
                </div>
            </div>
        </div>
    )
}

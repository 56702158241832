//@ts-nocheck

import './EventContainerDesc.scss'
import { useTranslation } from 'react-i18next'
import EventContainerModal from '../EventContainerModal/EventContainerModal'
import { getActive } from '../../../api/eventBattleReward'
import { useQuery } from 'react-query'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../../DataFetchError/DataFetchError'
import EventContainerUsersItems from '../EventContainerUsersItems/EventContainerUsersItems'
import EventContainerUsersItemsLast from '../EventContainerUsersItems/EventContainerUsersItemsLast'
import { MemoCountdown } from '../../MemoCountdown/MemoCountdown'
import React, { useState } from 'react'

export default function EventContainerDesc() {

    const { t, i18n } = useTranslation()

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['eventBattleReward'],
        () => getActive(),
        {
            keepPreviousData: true,
        }
    )

    const [activeComponent, setActiveComponent] = useState('current');

    return (
        <div className='w-100 h-100 d-flex flex-column'>
            {isSuccess &&
                <>
                    <div className={'event-container-desc w-100 d-flex flex-column position-relative'}>
                        <div className='d-flex justify-content-between'>
                            <div className='event-container-desc-title d-flex w-100' style={{ zIndex: 2 }}>
                                {i18n.language === 'en' &&
                                    (data.eventBattleReward.titleEn)
                                }
                                {i18n.language === 'ru' &&
                                    (data.eventBattleReward.titleRu)
                                }
                            </div>
                            <div className='text-white' style={{paddingRight: '8px', paddingTop: '18px', zIndex: 2}}>
                                <MemoCountdown timeAwait={data.timeAwait}/>
                            </div>
                        </div>
                        <div className='event-container-desc-text d-flex w-100 z-n2' style={{ zIndex: 2 }}>
                            <ul>
                                <li>{t("eventRaiting.text_1")}</li>
                                <li>{t("eventRaiting.text_2")}</li>
                                <li>{t("eventRaiting.text_3")}</li>
                                <li>{t("eventRaiting.text_4")}</li>
                            </ul>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className={`event-container-desc-button-left d-flex ${activeComponent === 'last' ? "event-container-desc-button-opacity" : ''}`} onClick={() => setActiveComponent('last')}>
                                <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.39805 20C8.30017 20.0002 8.20345 19.9787 8.11478 19.9373C8.02611 19.8958 7.94767 19.8354 7.88502 19.7601L0.152943 10.4323C0.0540876 10.3129 0 10.1627 0 10.0076C0 9.85252 0.0540876 9.70231 0.152943 9.58284L7.88502 0.255041C7.93994 0.185037 8.00832 0.126741 8.08613 0.083593C8.16394 0.0404446 8.2496 0.0133177 8.33807 0.00381283C8.42653 -0.00569205 8.516 0.0026176 8.6012 0.0282515C8.6864 0.0538855 8.76561 0.0963244 8.83414 0.153064C8.90267 0.209804 8.95915 0.279696 9.00023 0.358616C9.04131 0.437535 9.06618 0.523884 9.07335 0.612568C9.08052 0.701252 9.06986 0.790473 9.04199 0.874969C9.01412 0.959464 8.96961 1.03752 8.91108 1.10454L1.53212 10.0059L8.91108 18.9073C8.99218 19.0047 9.04383 19.1231 9.05998 19.2488C9.07612 19.3745 9.05609 19.5022 9.00224 19.6169C8.94839 19.7316 8.86294 19.8286 8.75593 19.8964C8.64891 19.9643 8.52477 20.0002 8.39805 20Z" fill="#B0B0B0"/>
                                </svg>
                            </div>
                            <div className='event-container-desc-button d-flex justify-content-center position-relative' data-bs-toggle="modal" data-bs-target="#eventModal" style={{ zIndex: 2 }}>
                                <svg width='94' height='28' viewBox="0 0 130 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M129 19.8599L110.945 38.7166H29.0301L8.30322 19.8599L29.0301 1H110.945L129 19.8599Z" stroke="#B0B0B0" strokeMiterlimit="10" />
                                    <path d="M122.697 20.1433L104.641 39H22.7269L2 20.1433L22.7269 1.28339H104.641L122.697 20.1433Z" stroke="#B0B0B0" strokeWidth="2" strokeMiterlimit="10" />
                                </svg>
                                <div className={'event-container-desc-button_text position-absolute top-50 start-50 translate-middle'}>{t("eventRaiting.rewards")}</div>
                            </div>
                            <div className={`event-container-desc-button-right d-flex ${activeComponent === 'current' ? "event-container-desc-button-opacity" : ''}`} onClick={() => setActiveComponent('current')} style={{zIndex: 2}}>
                                <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.677631 1.38543e-06C0.775514 -0.000157575 0.872229 0.0212522 0.960899 0.0627083C1.04957 0.104164 1.12802 0.164649 1.19066 0.23986L8.92274 9.56766C9.0216 9.68713 9.07568 9.83734 9.07568 9.99241C9.07568 10.1475 9.0216 10.2977 8.92274 10.4172L1.19066 19.745C1.13575 19.815 1.06736 19.8733 0.989555 19.9164C0.911745 19.9596 0.826081 19.9867 0.737618 19.9962C0.649154 20.0057 0.559681 19.9974 0.474481 19.9717C0.38928 19.9461 0.310078 19.9037 0.241545 19.8469C0.173012 19.7902 0.116537 19.7203 0.0754534 19.6414C0.0343694 19.5625 0.00950743 19.4761 0.00233591 19.3874C-0.00483561 19.2987 0.00582786 19.2095 0.033697 19.125C0.0615662 19.0405 0.106077 18.9625 0.164602 18.8955L7.54689 9.99407L0.164602 1.09269C0.0835046 0.995322 0.0318536 0.876851 0.0157074 0.75117C-0.000438821 0.625488 0.0195893 0.497808 0.0734429 0.383107C0.127297 0.268406 0.212742 0.17144 0.319757 0.103584C0.426771 0.0357279 0.550917 -0.000204677 0.677631 1.38543e-06Z" fill="#B0B0B0"/>
                                </svg>
                            </div>
                        </div>
                        <div className='event-container-desc-image position-absolute top-50 end-0 translate-middle-y'>
                            <img src={`/img/event/background/${data.eventBattleReward.topHero.pathImage}.png`} width={'auto'} height={'100%'} style={{ zIndex: 1, opacity: 0.3 }} />
                        </div>

                        <EventContainerModal />

                    </div>
                    {activeComponent === 'last' ? <EventContainerUsersItemsLast /> : <EventContainerUsersItems usersOldCoinEvent={data.usersOldCoinEvent} />}
                </>
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("mailContainer.dataFetchError")} />
            }

        </div>

    )
}
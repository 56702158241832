//@ts-nocheck

import './MinesContainerWait.scss';
import { useTranslation } from 'react-i18next'
import { MemoCountdown } from "../../MemoCountdown/MemoCountdown";
import { numberFormat } from '../../../utils/NumberFormat';
import { getMineRewardByMine } from '../../../utils/mineReward';
import i18n from '../../../utils/localization/i18next/i18next';

export default function MinesContainerWait({ mine }) {

    const { t } = useTranslation();

    return (<>
        <div className='hero-mines-container'>
            <div className='text-white hero-mines-label'>
                {i18n.language === 'en' &&
                    (mine.mine.nameEn)
                }
                {i18n.language === 'ru' &&
                    (mine.mine.nameRu)
                }
            </div>
            <div className='w-100 d-flex justify-content-between hero-mines-reward-container align-items-center' >
                <div className='d-flex align-items-center gap-1'>
                    <div className='hero-mines-gray-text'>
                        {t("mines.reward")} <span>{numberFormat(getMineRewardByMine(mine.mine))}</span>
                    </div>
                    {/* <div> */}
                        <img src='./img/loot/crystal-icon.png' width={'7.89px'} />
                    {/* </div> */}
                </div>

                <div className='hero-mines-gray-text d-flex justify-content-between' style={{width: '125px'}}>
                    {t("mines.timeAwait")} <MemoCountdown timeAwait={mine.timeAwait} />
                </div>
            </div>

            <div
                className='d-flex justify-content-between align-items-center hero-mines-add-container hero-mines-wait-container' style={{margin: '5px 0px'}}>
                <div className='hero-mines-btn-container position-relative'>
                    <svg width="82" height="26" viewBox="0 0 82 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M81.2105 12.9115L69.8071 24.821H18.0716L4.98096 12.9115L18.0716 1H69.8071L81.2105 12.9115Z"
                            stroke="#B0B0B0" strokeMiterlimit="10" />
                        <path d="M77.2296 13.0905L65.8261 25H14.0906L1 13.0905L14.0906 1.17896H65.8261L77.2296 13.0905Z"
                            stroke="#B0B0B0" strokeMiterlimit="10" />
                    </svg>
                    <div
                        className='position-absolute w-100 top-0 bottom-0 my-auto d-flex justify-content-center align-items-center hero-mines-gray-text'>
                        {t("mines.wait")}
                    </div>
                </div>

                <div className='d-flex hero-mines-imgs-wait' style={{gap:'6px', overflowX: 'auto'}}>
                    {mine.mine.UserMine.map(item => {
                        return (<div className='hero-mine-wait-img'><img src={`/img/heroes/${item.hero.pathImage}.jpg`}
                            width={'50px'} height={'50px'} /></div>)
                    })}
                </div>
            </div>
        </div>
    </>
    )
}
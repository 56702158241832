//@ts-nocheck

import React, { useState } from 'react'
import './SkipTimeModal.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import { useMutation, useQueryClient } from 'react-query'
import { skip } from '../../../api/skip'
import ToastSuccess from '../../Toasts/ToastSuccess/ToastSuccess'

export default function SkipTimeModal() {

    const { t } = useTranslation()

    const countUserLootEnergyBottle = useSelector((state) => state.userLootEnergyBottle.count)

    const [skipIsLoading, setSkipIsLoading] = useState(false)
    const queryClient = useQueryClient()
    const skipMutation = useMutation(() => skip(), {
        onSuccess: (data) => {
            if (data.ok) {
                queryClient.invalidateQueries(['userLoot']).then(() => {
                    setTimeout(() => {
                        setSkipIsLoading(false)
                        ToastSuccess(t("skipTimeModal.toastSuccess"), true)
                    }, 2000)
                })
            }
        },
        onError: () => {
            ToastError(t("toast.toastError"))
        }
    })

    const skipOnClick = () => {
        if (skipIsLoading) return

        if (countUserLootEnergyBottle - 25 < 0) {
            const shopHeroPriceElem = document.getElementById("skipPrice")
            shopHeroPriceElem.classList.toggle("skip-time-modal-btn-animate");
            setTimeout(() => {
                shopHeroPriceElem.classList.remove("skip-time-modal-btn-animate");
            }, 300);

            return;
        }

        setSkipIsLoading(true)

        skipMutation.mutate()
    }

    return (
        <div className="modal fade overflow-hidden" id="skipTime" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
            <div className={'modal-dialog modal-dialog-centered h-100 d-flex justify-content-center'}>
                <div className="modal-content bg-transparent p-0 m-0 border-0 d-flex align-items-center w-auto">
                    <div className='skip-time-modal position-relative'>
                        <div className='skip-time-modal-svg w-100 h-100 position-absolute' style={{ zIndex: 1 }}>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M82.6832 48.06C82.8924 46.4028 83 44.714 83 43C83 20.9086 65.0912 3 43 3C20.9086 3 3 20.9086 3 43C3 65.0912 20.9086 83 43 83C44.7416 83 46.4572 82.8888 48.14 82.6728M43 19V43L57.9536 50.4768M71 83V59M59 71H83" stroke="#B0B0B0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className='w-100 h-100' style={{ zIndex: 2 }}>
                            <div className='skip-time-modal-title w-100'>{t("skipTimeModal.title")}</div>
                            <div className='skip-time-modal-desc w-100'>{t("skipTimeModal.desc")}</div>
                            <div className='w-100 d-flex justify-content-center'>
                                <div onClick={skipOnClick} className='skip-time-modal-btn position-relative' style={{ zIndex: 3 }}>
                                    <svg width="130" height="40" viewBox="0 0 130 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M129 19.8599L110.944 38.7166H29.0299L8.3031 19.8599L29.0299 1H110.944L129 19.8599Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                                        <path d="M122.697 20.1432L104.641 39H22.7269L2 20.1432L22.7269 1.28333H104.641L122.697 20.1432Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                                    </svg>
                                    <div className='position-absolute top-50 start-50 translate-middle-y' style={{ paddingTop: '6px' }}>
                                        {!skipIsLoading &&
                                            <>
                                                <div className='d-flex align-items-center'>
                                                    <img src='/img/loot/energy-bottle.png' width={18.9} height={18} />
                                                    <div id='skipPrice' className='skip-time-modal-btn-price' style={{ color: countUserLootEnergyBottle - 25 < 0 ? "rgb(177, 61, 88)" : 'white' }}>25</div>
                                                </div>
                                                <div className='skip-time-modal-btn-time'>+2 {t("skipTimeModal.time")}</div>
                                            </>
                                        }
                                        {skipIsLoading &&
                                            <div style={{ paddingLeft: '10px' }}>
                                                <DataFetchLoader width={20} height={20} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}